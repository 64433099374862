import React from "react";
import { RestoredRowProps } from "./RestoredRow.types";
import { RestoredRowContainer, RestoredRowMessage } from "./RestoredRow.styles";

const RestoredRow: React.FC<RestoredRowProps> = ({
  message,
  colSpan,
}) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <RestoredRowContainer>
          <RestoredRowMessage>{message}</RestoredRowMessage>
        </RestoredRowContainer>
      </td>
    </tr>
  );
};

export default RestoredRow;
