import styled from "styled-components";

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
  padding: 19px 16px;
`;

export const ControlLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: rgb(31, 10, 53);
  gap: 8px;


  .p-inputtext{
    width: 200px;
  }

  input[type="text" i] {
    color: rgb(114, 82, 220);
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }


`;

export const DateInput = styled.input`
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
`;

export const Select = styled.select`
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
`;


export const ModeSwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ModeButton = styled.button<{ isActive: boolean }>`
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? "rgba(114, 82, 220, 1)" : "#e0e0e0")};
  color: ${({ isActive }) => (isActive ? "white" : "#333")};
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "#5a3bba" : "#d0d0d0")};
  }
`;
