import React, { useEffect, useState } from "react";
import {
  PaymentPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  TableWrapper,
  StatsWrapper,
  StatBlock,
} from "./PaymentPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import { Payment } from "../../services/paymentsService/paymentsService.types";
import { fetchPayments } from "../../services/paymentsService/paymentsService";
import { formatPrice } from "../../utils/formatNumber";

const PaymentPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");
  const [payments, setPayments] = useState<Payment[]>([]);
  const [totalPayments, setTotalPayments] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadPayments = async () => {
      setLoading(true);
      try {
        const data = await fetchPayments(currentPage, pageSize, orderBy);
        setPayments(data.results.payments);
        setTotalPayments(data.results.aggregated_data.total_payments);
        setTotalAmount(data.results.aggregated_data.total_amount);
      } catch (error) {
        console.error("Ошибка при загрузке данных платежей:", error);
      } finally {
        setLoading(false);
      }
    };

    loadPayments();
  }, [currentPage, pageSize, orderBy]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const headers = [
    { label: "Плательщик", key: "student", fieldName: "student__full_name"  },
    { label: "Дата", key: "created", fieldName:"created" },
    { label: "Сумма", key: "price", fieldName: "course__price" },
    // { label: "Окончание подписки", key: "subscription_end", fieldName: "subscription_end" },
  ];

  const rowAmountOptions = [
    { label: "7 платежей", value: 7 },
    { label: "14 платежей", value: 14 },
    { label: "20 платежей", value: 30 },
  ];

  return (
    <PaymentPageContainer>
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>История платежей</Title>
          <StatsWrapper>
            <StatBlock>
              Всего платежей: <span>{formatPrice(totalPayments)}</span>
            </StatBlock>
            <StatBlock>
              Общая сумма: <span>{formatPrice(totalAmount)} руб.</span>
            </StatBlock>
          </StatsWrapper>
        </HeaderContainer>
        {loading ? (
          <p>Загрузка данных...</p>
        ) : (
          <Table
            headers={headers}
            rows={payments.map((payment) => ({
              student: payment.student,
              created: new Date(payment.created).toLocaleDateString(),
              price: `${formatPrice(payment.price)} руб.`,
              subscription_end: new Date(payment.subscription_end).toLocaleDateString(),
            }))}
            currentPage={currentPage}
            totalPages={Math.ceil(totalPayments / pageSize)}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            onSort={handleSort}
            rowAmountOptions={rowAmountOptions}
            minHeight="230px"
          />
        )}
      </ContentWrapper>
    </PaymentPageContainer>
  );
};

export default PaymentPage;
