import React, { useRef, useEffect } from "react";
import { EditableTextarea } from "./AutoResizableTextarea.styles";

const AutoResizableTextarea: React.FC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
}> = ({ value, onChange, disabled }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <EditableTextarea
      ref={textareaRef}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default AutoResizableTextarea;
