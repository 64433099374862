import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createAssignmentReview, fetchAssignmentResponseById } from "../../services/assignmentResponseService/assignmentResponseService";
import {
    AssignmentResponsePageContainer,
    ContentWrapper,
    Header,
    Section,
    SectionTitle,
    SectionContent,
    RatingStars,
    CommentInput,
    SectionWrapper,
    LinkContainer,
    LinkItem,
    LinkIcon,
    ResponseText,
    Footer,
} from "./AssignmentResponseDetailPage.styles";
import Sidebar from "../../components/Sidebar";
import { AssignmentResponseDetail } from "./AssignmentResponseDetailPage.types";
import FileToDisplay from "../../components/FileToDisplay";
import Button from "../../components/Buttons/Button";
import SuccessModal from "../../components/Modals/SuccessModal";
import linkIcon from "../../assets/icons/linkIcon.svg";
import starFill from "../../assets/icons/starFill.svg";
import star from "../../assets/icons/star.svg";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";


const AssignmentResponseDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [assignmentResponse, setAssignmentResponse] = useState<AssignmentResponseDetail | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [rating, setRating] = useState<number>(0);
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);
    const [comment, setComment] = useState<string>(""); 
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const toast = useRef<Toast>(null);

    const isReviewCreated = !!assignmentResponse?.assignment_review?.teacher_comments; 

    useEffect(() => {
        const loadAssignmentResponse = async () => {
            setLoading(true);
            try {
                const response = await fetchAssignmentResponseById(Number(id));
                setAssignmentResponse(response);
                setRating(response.assignment_review?.rating || 0); 
                setComment(response.assignment_review?.teacher_comments || ""); 
            } catch (error) {
                toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки данных", life: 3000 });
                console.error("Ошибка загрузки данных:", error);
            } finally {
                setLoading(false);
            }
        };
        loadAssignmentResponse();
    }, [id]);

    const handleStarClick = (starIndex: number) => {
        if (!isReviewCreated) {
            setRating(starIndex + 1); 
        }
    };

    const handleStarHover = (starIndex: number) => {
        if (!isReviewCreated) {
            setHoveredRating(starIndex + 1); 
        }
    };

    const handleStarMouseLeave = () => {
        setHoveredRating(null); 
    };

    const handleSave = async () => {
        if (!assignmentResponse || isReviewCreated) return;

        try {
            await createAssignmentReview({
                response: assignmentResponse.id,
                teacher_comments: comment,
                rating,
            });
            setIsModalVisible(true);
            setTimeout(() => {
                setIsModalVisible(false);
            }, 2000);
        } catch (error: any) {
            const errorMessage = error.response?.data?.detail || "Ошибка при сохранении фидбека";

            toast.current?.show({ severity: "error", summary: "Ошибка", detail: errorMessage, life: 3000 });
            console.error("Ошибка при сохранении фидбека:", error);
        }
    };

    const isButtonDisabled = isReviewCreated || rating === 0 || comment.trim() === ""; 
    if (loading)
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <ProgressSpinner />
            </div>
        );
    

    if (!assignmentResponse) return <div>Данные не найдены</div>;

    return (
        <AssignmentResponsePageContainer>
            <Sidebar />
            <Toast ref={toast} />
            <ContentWrapper>
                <Header>
                    <h1>{assignmentResponse.student.full_name}</h1>
                </Header>
                <SectionWrapper>
                    {assignmentResponse.files_from_storage.length > 0 && ( 
                        <Section>
                            <SectionTitle>Прикрепленные файлы</SectionTitle>
                            <SectionContent>
                                <FileToDisplay files_from_storage={assignmentResponse.files_from_storage} />
                            </SectionContent>
                        </Section>
                    )}
                    {assignmentResponse.assignment.video_url && ( 
                        <Section>
                            <SectionTitle>Ссылка</SectionTitle>
                            <SectionContent>
                                <LinkContainer as="a" href={assignmentResponse.assignment.video_url} target="_blank" rel="noopener noreferrer">
                                    <LinkIcon src={linkIcon} alt="Ссылка" />
                                    <LinkItem>
                                        {assignmentResponse.assignment.video_url}
                                    </LinkItem>
                                </LinkContainer>
                            </SectionContent>
                        </Section>
                    )}

                    <Section>
                        <SectionTitle>Ответ пользователя</SectionTitle>
                        <SectionContent>
                            <ResponseText>{assignmentResponse.text}</ResponseText>
                        </SectionContent>
                    </Section>
                    <Section>
                        <SectionTitle>Ответ организатора</SectionTitle>
                        <CommentInput
                            placeholder="Напишите комментарий ученику"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)} 
                            disabled={isReviewCreated}
                        />
                    </Section>
                    <Section>
                        <SectionTitle>Поставьте оценку</SectionTitle>
                        <RatingStars>
                            {[...Array(5)].map((_, index) => (
                                <img
                                    key={index}
                                    src={index < (hoveredRating ?? rating) ? starFill : star}
                                    alt={`${index + 1} star`}
                                    onClick={() => handleStarClick(index)} 
                                    onMouseEnter={() => handleStarHover(index)} 
                                    onMouseLeave={handleStarMouseLeave}
                                    style={{
                                        cursor: isReviewCreated ? "default" : "pointer",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                />
                            ))}
                        </RatingStars>
                    </Section>
                </SectionWrapper>
                <Footer>
                    <Button
                        onClick={handleSave}
                        disabled={isButtonDisabled}
                        variant="flatFilled"
                        width="200px"
                        height="50px"
                    >
                        {isReviewCreated ? "Ответ отправлен" : "Ответить"}
                    </Button>
                </Footer>
                {isModalVisible && <SuccessModal message="Фидбек успешно сохранён!" onClose={() => setIsModalVisible(false)} />}
            </ContentWrapper>
        </AssignmentResponsePageContainer>
    );
};

export default AssignmentResponseDetailPage;

