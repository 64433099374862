import { Lesson } from "../../pages/LessonsPage/LessonsPage.types";
import axiosInstance from "../axiosConfig";
import { LessonData, LessonResponse } from "./lessonService.types";

// Получение уроков
export const fetchLessons = async (
  courseId: number,
  page: number = 1,
  pageSize: number = 7,
  ordering: string = ""
): Promise<LessonResponse> => {
  const url = `/courses/${courseId}/lessons/`;
  try {
    const response = await axiosInstance.get<LessonResponse>(url, {
      params: { page, page_size: pageSize, ordering },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении уроков:", error);
    throw error;
  }
};

// Получение урока по ID
export const fetchLessonById = async (
  courseId: number,
  lessonId: number
): Promise<Lesson> => {
  const url = `/courses/${courseId}/lessons/${lessonId}/`;
  try {
    const response = await axiosInstance.get<Lesson>(url);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении урока с ID ${lessonId}:`, error);
    throw error;
  }
};

// Удаление урока
export const deleteLesson = async (
  courseId: number,
  lessonId: number
): Promise<void> => {
  const url = `/courses/${courseId}/lessons/${lessonId}/`;
  try {
    await axiosInstance.delete(url);
  } catch (error) {
    console.error(`Ошибка при удалении урока с ID ${lessonId}:`, error);
    throw error;
  }
};

// "Мягкое" удаление урока
export const softDeleteLesson = async (
  courseId: number,
  lessonId: number
): Promise<void> => {
  const url = `/courses/${courseId}/lessons/${lessonId}/`;
  try {
    await axiosInstance.patch(url, { is_deleted: true });
  } catch (error) {
    console.error(`Ошибка при мягком удалении урока с ID ${lessonId}:`, error);
    throw error;
  }
};

// Восстановление урока
export const restoreLesson = async (
  courseId: number,
  lessonId: number
): Promise<void> => {
  const url = `/courses/${courseId}/lessons/${lessonId}/`;
  try {
    await axiosInstance.patch(url, { is_deleted: false });
  } catch (error) {
    console.error(`Ошибка при восстановлении урока с ID ${lessonId}:`, error);
    throw error;
  }
};

// Получение категорий уроков
export const fetchLessonCategories = async (
  courseId: number
): Promise<string[]> => {
  const url = `/courses/lesson-categories/`;
  try {
    const response = await axiosInstance.get<string[]>(url, {
      params: { course_id: courseId },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при загрузке категорий уроков:", error);
    throw error;
  }
};

// Создание урока
export const createLesson = async (
  courseId: number,
  lessonData: LessonData
): Promise<Lesson> => {
  const url = `/courses/${courseId}/lessons/`;
  const formData = new FormData();

  formData.append("order", String(lessonData.order));
  formData.append("title", lessonData.title);
  if (lessonData.category_title) formData.append("category_title", lessonData.category_title);
  if (lessonData.content) formData.append("content", lessonData.content);
  if (lessonData.video_url) formData.append("video_url", lessonData.video_url);

  if (lessonData.files) {
    lessonData.files.forEach((file) => {
      if (typeof file === "string") {
        formData.append("existing_files", file);
      } else {
        formData.append("files", file);
      }
    });
  }

  try {
    const response = await axiosInstance.post<Lesson>(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании урока:", error);
    throw error;
  }
};

// Обновление урока
export const updateLesson = async (
  courseId: number,
  lessonId: number,
  lessonData: LessonData
): Promise<Lesson> => {
  const url = `/courses/${courseId}/lessons/${lessonId}/`;
  const formData = new FormData();

  if (lessonData.course) formData.append("course", String(lessonData.course));
  if (lessonData.order !== undefined) formData.append("order", String(lessonData.order));
  if (lessonData.title) formData.append("title", lessonData.title);
  if (lessonData.category_title) formData.append("category_title", lessonData.category_title);
  if (lessonData.content) formData.append("content", lessonData.content);
  if (lessonData.video_url) formData.append("video_url", lessonData.video_url); else formData.append("video_url", "");
  if (lessonData.file_ids !== undefined) {
    formData.append("file_ids", lessonData.file_ids || "");
  }

  if (lessonData.files) {
    lessonData.files.forEach((file) => {
      if (typeof file === "string") {
        formData.append("existing_files", file);
      } else {
        formData.append("files", file);
      }
    });
  }

  try {
    const response = await axiosInstance.put<Lesson>(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении урока:", error);
    throw error;
  }
};
