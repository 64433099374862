import React from "react";
import { ToggleSwitchContainer, ToggleButton } from "./ToggleSwitch.styles";
interface ToggleSwitchProps {
  options: string[]; // Массив с названиями опций
  activeOption: string; // Текущая активная опция
  onToggle: (option: string) => void; // Колбэк для переключения опций
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  options,
  activeOption,
  onToggle,
}) => {
  return (
    <ToggleSwitchContainer>
      {options.map((option) => (
        <ToggleButton
          key={option}
          isActive={activeOption === option}
          onClick={() => onToggle(option)}
        >
          {option}
        </ToggleButton>
      ))}
    </ToggleSwitchContainer>
  );
};

export default ToggleSwitch;
