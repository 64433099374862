import styled from "styled-components";


export const EditableTextarea = styled.textarea`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: rgb(31, 10, 53);
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  outline: none;
  resize: none;
  background: none;
  overflow: hidden;
`;