import styled from "styled-components";

export const FileDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgb(235, 235, 235);
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 8px 12px;
  position: relative;
  max-width: 150px;
      width: 87px;
    height: 87px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  text-align: center;
  padding: 4px 8px;
  font-size: 12px;
  position: absolute;
  bottom: 80%; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: opacity 0.3s ease;

  }
`;

export const FileIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
`;

export const FileName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 60px;
  text-align: center;
`;


export const RemoveFileButton = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;