import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
  AssignmentsPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
  StyledLinkItem,
} from "./AssignmentsPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import Button from "../../components/Buttons/Button";
import IconButton from "../../components/Buttons/IconButton";
import delIcon from "../../assets/icons/delIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import smallFileIcon from "../../assets/icons/smallFileIcon.svg";
import linkIcon from "../../assets/icons/linkIcon.svg";
import {
  fetchAssignments,
  createAssignment,
  updateAssignment,
  deleteAssignment,
} from "../../services/assignmentService/assignmentService";
import { AssignmentRow } from "./AssignmentsPage.types";
import { AssignmentData } from "../../services/assignmentService/assignmentService.types";
import { ConfirmModal, CreateModal } from "../../components/Modals";
import { FileDisplay } from "../../components/FileToDisplay/FileToDisplay.types";
import DeletedRow from "../../components/DeletedRow";
import RestoredRow from "../../components/RestoredRow";
import { Toast } from "primereact/toast";

const AssignmentsPage: React.FC = () => {
  const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();
  const [assignments, setAssignments] = useState<AssignmentRow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [assignmentToEdit, setAssignmentToEdit] = useState<AssignmentRow | null>(null);
  const [assignmentToDelete, setAssignmentToDelete] = useState<AssignmentRow | null>(null);

  const [storageFiles, setStorageFiles] = useState<FileDisplay[]>([]);

  const [pendingDelete, setPendingDelete] = useState<number | null>(null);
  const [recentlyRestored, setRecentlyRestored] = useState<number | null>(null);
  const [recentlyCreated, setRecentlyCreated] = useState<number | null>(null);
  const [recentlyEdited, setRecentlyEdited] = useState<number | null>(null);
  const deleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [externalErrors, setExternalErrors] = useState<Record<string, string>>({});

  const toast = useRef<Toast>(null);

  useEffect(() => {
    loadAssignments();
  }, [currentPage, pageSize, orderBy]);

  const loadAssignments = async () => {
    setLoading(true);
    try {
      const response = await fetchAssignments(
        Number(courseId),
        Number(lessonId),
        currentPage,
        pageSize,
        orderBy
      );
      setAssignments(
        response.results.map((assignment) => {
          const assignmentRow: AssignmentRow = {
            id: assignment.id,
            title: assignment.title,
            lesson: `Урок ${assignment.lesson}`,
            content: assignment.content,
            files_from_storage: assignment.files_from_storage || [],
            video_url: assignment.video_url,
            files: assignment.files,
            videoIcon: assignment.video_url ? (
              <StyledLinkItem
                href={assignment.video_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkIcon} alt="Ссылка на видео" />
                Ссылка
              </StyledLinkItem>
            ) : (
              "—"
            ),
            filesIcon: assignment.with_files ? (
              <StyledLinkItem>
                <img src={smallFileIcon} alt="Файлы" />
                Файлы
              </StyledLinkItem>
            ) : (
              "Нет файлов"
            ),
            edit: (
              <IconButton
                icon={editIcon}
                altText="Редактировать"
                onClick={() => openEditModal(assignmentRow)}
              />
            ),
            delete: (
              <IconButton
                icon={delIcon}
                altText="Удалить"
                onClick={() => {
                  setAssignmentToDelete(assignmentRow);
                  setIsDeleteModalOpen(true);
                }}
              />
            ),
          };

          return assignmentRow;
        })
      );

      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки домашних заданий", life: 3000 });
      console.error("Ошибка загрузки домашних заданий:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const handleRemoveStorageFile = (fileId: number) => {
    setStorageFiles((prevFiles) =>
      (prevFiles || []).filter((file) => file.id !== fileId)
    );
  };

  const openCreateModal = () => {
    setStorageFiles([]);
    setAssignmentToEdit(null);
    setIsCreateModalOpen(true);
  };

  const openEditModal = (assignment: AssignmentRow) => {
    setStorageFiles(assignment?.files_from_storage || []);
    setAssignmentToEdit(assignment);
    setIsCreateModalOpen(true);
  };

  const handleDeleteAssignment = () => {
    if (assignmentToDelete) {
      const assignmentId = assignmentToDelete.id;
      setPendingDelete(assignmentId);
      setIsDeleteModalOpen(false);
      setAssignmentToDelete(null);

      deleteTimeoutRef.current = setTimeout(async () => {
        try {
          await deleteAssignment(
            Number(courseId),
            Number(lessonId),
            assignmentId
          );
          setPendingDelete(null);
          loadAssignments();
        } catch (error) {
          toast.current?.show({
            severity: "error",
            summary: "Ошибка",
            detail: "Ошибка удаления задания",
            life: 3000
          })
          console.error("Ошибка при удалении задания:", error);
        }
      }, 5000);
    }
  };

  const restoreAssignmentHandler = (assignmentId: number) => {
    if (deleteTimeoutRef.current) {
      clearTimeout(deleteTimeoutRef.current);
      deleteTimeoutRef.current = null;
    }

    setRecentlyRestored(assignmentId);
    setPendingDelete(null);

    loadAssignments();

    setTimeout(() => setRecentlyRestored(null), 3000);
  };

  const handleCreateOrEditAssignment = async (
    formData: Record<string, string | File[] | null | FileDisplay[]>,
    options?: { isEditing?: boolean }
  ) => {
    const { isEditing } = options || {};
    const title = typeof formData.title === 'string' ? formData.title : '';
    const content = typeof formData.content === 'string' ? formData.content : '';
    const video_url = typeof formData.video_url === 'string' ? formData.video_url : '';

    setExternalErrors({}); // Сброс ошибок
    try {
      let existingFileIdsString = "";

      if (Array.isArray(formData.files_from_storage)) {
        setStorageFiles(formData.files_from_storage as FileDisplay[]);
        const updatedStorageFiles = formData.files_from_storage as FileDisplay[];
        const existingFileIds = updatedStorageFiles.map((file) => file.id);
        existingFileIdsString = existingFileIds.join(",");
      } else {
        const existingFileIds: number[] = Array.isArray(storageFiles)
          ? storageFiles.map((file) => file.id)
          : [];
        existingFileIdsString = existingFileIds.join(",");
      }

      const uploadedFiles: File[] = Array.isArray(formData.files)
        ? (formData.files as File[])
        : [];

      const assignmentData: AssignmentData = {
        lesson: Number(lessonId),
        title: title,
        content: content,
        video_url: video_url,
        files: uploadedFiles,
        file_ids: existingFileIdsString,
      };

      if (isEditing && assignmentToEdit) {
        await updateAssignment(
          Number(courseId),
          Number(lessonId),
          assignmentToEdit.id,
          assignmentData
        );
        setRecentlyEdited(assignmentToEdit.id);
      } else {
        const createdAssignment = await createAssignment(
          Number(courseId),
          Number(lessonId),
          assignmentData
        );
        setRecentlyCreated(createdAssignment.id);
      }

      setStorageFiles([]);
      setIsCreateModalOpen(false);
      loadAssignments();

      setTimeout(() => {
        setRecentlyCreated(null);
        setRecentlyEdited(null);
      }, 3000);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};

        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }

        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка сохранения задания:", error);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка сохранения задания",
          life: 3000,
        });
      }
    }
  };


  const getTableRows = () => {
    const rows = assignments.map((assignment) => {
      if (assignment.id === pendingDelete) {
        return {
          title: (
            <DeletedRow
              id={assignment.id}
              onRestore={restoreAssignmentHandler}
              colSpan={headers.length + 1}
              message={`Задание "${assignment.title}" будет удалено через 5 секунд.`}
              targetText="Восстановить задание"
            />
          ),
        };
      }
      return {
        title: assignment.title,
        lesson: assignment.lesson,
        videoIcon: assignment.videoIcon,
        filesIcon: assignment.filesIcon,
        edit: assignment.edit,
        delete: assignment.delete,
      };
    });

    if (recentlyRestored !== null) {
      const restoredIndex = assignments.findIndex(
        (a) => a.id === recentlyRestored
      );
      if (restoredIndex !== -1) {
        rows.splice(restoredIndex + 1, 0, {
          title: (
            <RestoredRow
              colSpan={headers.length + 1}
              message="Задание успешно восстановлено"
            />
          ),
        });
      }
    }

    if (recentlyCreated !== null) {
      const createdIndex = assignments.findIndex((a) => a.id === recentlyCreated);
      if (createdIndex !== -1) {
        rows.splice(createdIndex + 1, 0, {
          title: (
            <RestoredRow
              colSpan={headers.length + 1}
              message="Задание успешно создано"
            />
          ),
        });
      }
    }

    if (recentlyEdited !== null) {
      const editedIndex = assignments.findIndex((a) => a.id === recentlyEdited);
      if (editedIndex !== -1) {
        rows.splice(editedIndex + 1, 0, {
          title: (
            <RestoredRow
              colSpan={headers.length + 1}
              message="Задание успешно отредактировано"
            />
          ),
        });
      }
    }

    return rows;
  };

  const headers = [
    { label: "Задание", key: "title", fieldName: "title" },
    { label: "Урок", key: "lesson" },
    { label: "Видео", key: "videoIcon", sortable: false },
    { label: "Файлы", key: "filesIcon", sortable: false },
    { label: "", key: "edit", sortable: false },
    { label: "", key: "delete", sortable: false },
  ];

  const rowAmountOptions = [
    { label: "7 заданий", value: 7 },
    { label: "15 заданий", value: 15 },
    { label: "20 заданий", value: 20 },
  ];

  return (
    <AssignmentsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <motion.div initial={{ height: "auto" }}>
          <HeaderContainer>
            <Title>Домашние задания</Title>
            <ButtonWrapper>
              <Button variant="active" onClick={openCreateModal}>
                Новое задание
              </Button>
            </ButtonWrapper>
          </HeaderContainer>
        </motion.div>
        <Table
          headers={headers}
          rows={getTableRows()}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onSort={handleSort}
          rowAmountOptions={rowAmountOptions}
          specialRowKey="title"
          loading={loading}
        />
        {isCreateModalOpen && (
          <CreateModal
            title={assignmentToEdit ? "Редактировать задание" : "Новое задание"}
            hasDragAndDrop={true}
            files_from_storage={storageFiles}
            onRemoveFile={handleRemoveStorageFile}
            submitButtonText={assignmentToEdit ? "Сохранить задание" : "Создать задание"}
            fields={[
              { name: "title", type: "text", placeholder: "Название", required: true },
              { name: "content", type: "textarea", placeholder: "Описание", required: true },
            ]}
            onSubmit={(formData) =>
              handleCreateOrEditAssignment(formData, { isEditing: !!assignmentToEdit })
            }
            onCancel={() => {
              setStorageFiles([]);
              setIsCreateModalOpen(false);
            }}
            initialValues={
              assignmentToEdit
                ? {
                  title: assignmentToEdit.title,
                  content: assignmentToEdit.content,
                  video_url: assignmentToEdit.video_url || "",
                }
                : {}
            }
            externalErrors={externalErrors}
          />
        )}
        {isDeleteModalOpen && (
          <ConfirmModal
            isOpen={isDeleteModalOpen}
            title="Удаление задания"
            message={`Вы уверены, что хотите удалить задание "${assignmentToDelete?.title}"?`}
            onConfirm={handleDeleteAssignment}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
        )}
      </ContentWrapper>
    </AssignmentsPageContainer>
  );
};

export default AssignmentsPage;
