import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  height: 271px;
  width: 429px;
  border-bottom: 1px solid rgb(225, 224, 229);
  border-radius: 16px;
  background: rgb(255, 255, 255);

  p {
    text-align: center;
    margin-top: 30px;
    color: rgb(31, 10, 53);
    font-size: 28px;
    font-weight: 500;
  }
`;

export const ModalIcon = styled.img`
    margin-bottom: 30px;
`;

export const CloseButton = styled.button`
  position: relative;
  top: 20px;
  left: 180px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #000;
  }
`;
