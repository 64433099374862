import React from "react";
import { DeletedRowProps } from "./DeletedRow.types";
import { DeletedRowContainer, DeletedRowMessage, RestoreButton } from "./DeletedRow.styles";

const DeletedRow: React.FC<DeletedRowProps> = ({
  id,
  message,
  onRestore,
  colSpan,
  targetText
}) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <DeletedRowContainer>
          <DeletedRowMessage>{message}</DeletedRowMessage>
          <RestoreButton onClick={() => onRestore(id)}>
            {targetText}
          </RestoreButton>
        </DeletedRowContainer>
      </td>
    </tr>
  );
};

export default DeletedRow;
