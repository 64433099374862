import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: radial-gradient(
      51% 51% at 50% 121%,
      rgba(131, 102, 228, 0.6),
      rgba(131, 102, 228, 0)
    ),
    rgb(247, 248, 248);
`;

export const Content = styled.div`
  max-width: 600px;
  padding: 20px;
`;

export const ErrorCode = styled.h1`
  color: rgb(31, 10, 53);
  font-size: 290px;
  font-weight: 600;
  line-height: 1;
`;

export const ErrorMessage = styled.p`
  color: rgb(31, 10, 53);
  font-family: Inter, sans-serif;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 43px;
`;

export const HomeLink = styled.a`

  border: 1px solid rgb(114, 82, 220);
  border-radius: 12px;
  padding: 14px 28px 14px 28px;
  color: rgb(114, 82, 220);
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;

  &:hover {
    border-radius: 12px;
    background: rgb(144, 112, 245);
    color: white;
  }
`;
