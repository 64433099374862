import React, { useState } from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  Input,
  FieldWrapper,
  ErrorMessage,
  InfoText,
  HighlightText,
  Divider,
} from  "./WithdrawModal.styles";
import { WithdrawModalProps } from "./WithdrawModal.types";
import Button from "../Buttons/Button";
import closeIcon from "../../assets/icons/closeIcon.svg";
import { formatNumber } from "../../utils/formatNumber";

const WithdrawModal: React.FC<WithdrawModalProps> = ({ availableAmount, onSubmit, onCancel }) => {
  const [amount, setAmount] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [errors, setErrors] = useState<{ amount?: string; cardNumber?: string }>({});

  const handleSubmit = () => {
    const newErrors: { amount?: string; cardNumber?: string } = {};
    if (!amount.trim()) {
      newErrors.amount = "Введите сумму";
    } else if (isNaN(Number(amount)) || Number(amount) <= 0) {
      newErrors.amount = "Некорректная сумма";
    }

    if (!cardNumber.trim()) {
      newErrors.cardNumber = "Введите номер карты";
    } else if (!/^\d{16}$/.test(cardNumber)) {
      newErrors.cardNumber = "Некорректный номер карты";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      onSubmit({ amount, cardNumber });
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Вывод средств</h2>
          <CloseButton onClick={onCancel}>
            <img src={closeIcon} alt="Закрыть" />
          </CloseButton>
        </ModalHeader>
        <Divider></Divider>
        <ModalBody>
          <InfoText>
            <span>Доступно для вывода:</span><HighlightText>{formatNumber(availableAmount)} руб.</HighlightText>
          </InfoText>
          <FieldWrapper>
            {errors.amount && <ErrorMessage>{errors.amount}</ErrorMessage>}
            <Input
              type="text"
              placeholder="Сумма"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              hasError={!!errors.amount}
            />
            {errors.cardNumber && <ErrorMessage>{errors.cardNumber}</ErrorMessage>}
            <Input
              type="text"
              placeholder="Номер карты"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              hasError={!!errors.cardNumber}
            />
          </FieldWrapper>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleSubmit} disabled={!amount || !cardNumber}>
            Добавить
          </Button>
          <p>Проблемы с выводом?</p>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default WithdrawModal;
