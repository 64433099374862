import axios, { Method, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { UserTokenPayload } from "./authService.types";

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://127.0.0.1:8000/api/v1';
console.log(BASE_URL);

  // Регистрация пользователя
export const registerUser = async (formData: Record<string, string>, confirmPassword: string): Promise<AxiosResponse<any, any>> => {
  try {
    const registrationData = {
      email: formData.email,
      password: formData.password,
      confirm_password: confirmPassword,
      first_name: formData.firstName,
      last_name: formData.lastName,
      organization_name: formData.organization_name,
      verification_code: formData.verification_code,
    };

    const response = await axios.post(`${BASE_URL}/users/`, registrationData);
    console.log("Пользователь успешно зарегистрирован");
    return response;
  } catch (error) {
    console.error("Ошибка при регистрации:", error);
    throw error;
  }
};


export const sendPasswordResetCode = async (email: string, type: string = "reset"): Promise<void> => {
  await axios.post(`${BASE_URL}/auth/send-reset-code/`, { email, type }, {
    withCredentials: true,
  });
};

export const verifyPasswordResetCode = async (
  resetCode: string,
  email: string
): Promise<void> => {
  await axios.post(`${BASE_URL}/auth/verify-reset-code/`, { resetCode, email });
};

export const resetPassword = async (data: {
  email: string;
  password: string;
  confirmPassword: string;
  
}): Promise<void> => {
  await axios.post(`${BASE_URL}/auth/reset-password/`, data);
};


export const sendVerificationCode = async (email: string, type: string): Promise<void> => {
    try {
      await axios.post(`${BASE_URL}/auth/send-verification-code/`, { email, type });
      console.log("Код успешно отправлен!");
    } catch (error) {
      console.error("Ошибка при отправке кода:", error);
      throw error;
    }
  };


  // Функция для сохранения токенов
  export const getAuthTokens = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/token/`, {
        email: email,
        password: password,
      });

      const { access, refresh, first_name, last_name } = response.data;
      // Декодируем access токен для получения информации о пользователе
      const decodedToken = jwtDecode<UserTokenPayload>(access);
      const { user_id } = decodedToken;

      // Пишем все данные пользователя в куки
      Cookies.set("user_id", user_id);
      Cookies.set("access_token", access);
      Cookies.set("refresh_token", refresh);
      Cookies.set("email", email);
      Cookies.set("first_name", first_name);
      Cookies.set("last_name", last_name);

      return { access, refresh, user_id };
    } catch (error) {
      throw error;
    }
  };


  export const createReferral = async (user_id: string, referral_token: string): Promise<void> => {
    try {
      const referralData = {
        user_id: user_id,
        referral_token: referral_token,
      };

      await axios.post(`${BASE_URL}/auth/create-referral/`, referralData);

      console.log("Реферальный код успешно создан!");
    } catch (error) {
      console.error("Ошибка при создании реферального кода:", error);
      throw error;
    }
  };