import styled, { css } from "styled-components";
import { FooterLinkItemProps, InputProps } from "./FormModal.types";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Затемнение */
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export const FormWrapper = styled.div`
  padding: 30px;
  border-radius: 8px;
  text-align: center;

  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 24px;

  box-shadow: 2px 6px 29px 0px rgba(0, 0, 0, 0.09);
  background: rgb(255, 255, 255);

  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 32px;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  }
  
  }
`;

export const Input = styled.input<InputProps>`
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid rgba(31, 10, 53, 0.1);
  background: rgb(247, 248, 248);
  outline-width: 0;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}

  /* Состояние ошибки */
  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid rgb(242, 40, 98);
      background: rgba(114, 82, 220, 0.15);
    `}

  /* Состояние фокуса */
  &:focus {
    border: 1px solid rgb(114, 82, 220);
    background: rgba(114, 82, 220, 0.15);
  }

  /* Состояние наведения */
  &:hover {
    border: 1px solid rgba(114, 82, 220, 0.1);
    background: rgba(114, 82, 220, 0.08);
  }

  &::placeholder {
    position: absolute;
    top: ${({ hasError }) => (hasError ? '19px' : '14px')};
    transition: top 0.2s ease, color 0.2s ease;
  }
`;


export const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ff4d4d;
  margin-bottom: 5px;
  position: sticky;
  left: 16px;
  top: 7px;
`;

export const FooterLinksWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

export const FooterLinkItem = styled.a<FooterLinkItemProps>`
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  
  ${({ variant }) =>
    variant === "primary" &&
    css`
      color: rgb(134, 106, 229);
      }
    `}

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      color: rgba(31, 10, 53, 0.5);
      }
    `}
`;


export const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const PasswordIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-right: 5px;
`;
