import exp from "constants";
import styled from "styled-components";

export const ReferralPageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  height: calc(100vh - 24px);
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  overflow-x: auto;
  scrollbar-width: none;
`;

export const InfoContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const FriendInviteConteiner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 183px;
  gap: 40px;
  min-width: 360px;
  padding: 16px;
  border: 1px solid rgba(31, 10, 53, 0.08);
  border-radius: 16px;
  box-shadow: 0px 0px 0px 6px rgba(115, 83, 221, 0.37);
  background: linear-gradient(
    138.03deg,
    rgb(114, 82, 220) 32.06%,
    rgba(114, 82, 220, 0) 155.365%
  );
`;

export const FriendInviteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const FriendInviteTitle = styled.h1`
  color: rgb(255, 255, 255);
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
`;

export const FriendInviteInfo = styled.span`
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
`;

export const CopyLinkWrapper = styled.div`
  display: flex;
`;

export const Button = styled.button`
  border-radius: 8px;
  border: none;
  background: white;
  padding: 12px 24px;
  cursor: pointer;
  margin-right: auto;

  img {
    margin-right: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  max-height: 183px;
  min-width: 220px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;
  box-shadow: 0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
`;

export const BalanceTitle = styled.h1`
  color: rgb(31, 10, 53);
  font-size: 16px;
  font-weight: 400;
`;

export const BalanceInfo = styled.span`
  color: rgb(31, 10, 53);
  font-size: 28px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WithdrawMoneyButton = styled.button`
  padding: 14px 28px 14px 28px;
  border-radius: 10px;
  background: rgba(114, 82, 220, 0.1);
  color: rgb(114, 82, 220);
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const StatsWrapper = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  row-gap: 24px;
  gap: 24px;
  min-width: 356px;
  padding: 16px 16px;
  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;

  box-shadow: 0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);

  overflow: auto;
  box-sizing: border-box;
`;

export const StatBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: rgb(31, 10, 53);
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;

  span {
    color: rgb(31, 10, 53);
    font-size: 28px;
    font-weight: 600;
  }
`;

export const ChartWrapper = styled.div`
  margin-top: 32px;
  padding: 0;
  background: white;
  border-radius: 16px;
  height: calc(100% - 32px);
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);

  .recharts-wrapper {
    height: 100%;
  }
`;
