import axiosInstance from "../axiosConfig";
import { Course, CourseResponse } from "./courseService.types";

// Получение списка курсов
export const fetchCourses = async (
  page: number = 1,
  pageSize: number = 7,
  ordering: string = ""
): Promise<CourseResponse> => {
  const url = `/courses/`;
  try {
    const response = await axiosInstance.get<CourseResponse>(url, {
      params: { page, page_size: pageSize, ordering },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении курсов:", error);
    throw error;
  }
};

// Получение курса по ID
export const fetchCourseById = async (id: number): Promise<Course> => {
  const url = `/courses/${id}/`;
  try {
    const response = await axiosInstance.get<Course>(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении курса по ID:", error);
    throw error;
  }
};

// Удаление курса
export const deleteCourse = async (id: number): Promise<void> => {
  const url = `/courses/${id}/`;
  try {
    await axiosInstance.delete(url);
  } catch (error) {
    console.error(`Ошибка при удалении курса с ID ${id}:`, error);
    throw error;
  }
};

// Обновление курса
export const updateCourse = async (
  courseId: number,
  courseData: Partial<Course>
): Promise<Course> => {
  const url = `/courses/${courseId}/`;
  try {
    const response = await axiosInstance.patch<Course>(url, courseData);
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении курса:", error);
    throw error;
  }
};

// Восстановление курса
export const restoreCourse = async (courseId: number): Promise<void> => {
  const url = `/courses/${courseId}/`;
  try {
    await axiosInstance.patch(url, { is_deleted: false });
  } catch (error) {
    console.error("Ошибка при восстановлении курса:", error);
    throw error;
  }
};

// Мягкое удаление курса
export const softDeleteCourse = async (courseId: number): Promise<void> => {
  const url = `/courses/${courseId}/`;
  try {
    await axiosInstance.patch(url, { is_deleted: true });
  } catch (error) {
    console.error("Ошибка при мягком удалении курса:", error);
    throw error;
  }
};

// Создание курса
export const createCourse = async (courseData: {
  title: string;
  category: string;
  price: string;
  description: string;
  instructor?: number;
}): Promise<Course> => {
  const url = `/courses/`;
  try {
    const response = await axiosInstance.post<Course>(url, courseData);
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании курса:", error);
    throw error;
  }
};

// Обновление курса по ID
export const updateCourseById = async (
  id: number,
  courseData: Partial<Course>
): Promise<Course> => {
  const url = `/courses/${id}/`;
  try {
    const response = await axiosInstance.patch<Course>(url, courseData);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при обновлении курса с ID ${id}:`, error);
    throw error;
  }
};

// Получение категорий
export const fetchCategories = async (): Promise<string[]> => {
  const url = `/courses/categories/`;
  try {
    const response = await axiosInstance.get<string[]>(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при загрузке категорий:", error);
    throw error;
  }
};
