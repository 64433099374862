import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  width: 429px;
  border-radius: 16px 16px 16px 16px;
  background: rgb(255, 255, 255);
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px;

  h2 {
    color: rgb(31, 10, 53);
    font-size: 28px;
    font-weight: 700;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(225, 224, 229);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

export const ModalBody = styled.div`
  p {
    color: rgb(185, 185, 185);
    font-size: 18px;
    font-weight: 500;
    margin: 30px 30px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 30px 30px 30px 30px;


  button {
    flex: 1;
  }
`;
