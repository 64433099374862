import axiosInstance from "../axiosConfig";
import { SupportRequestCreateResponse, SupportRequestPayload, SupportRequestResponse } from "./supportRequestService.types";

// Получение запросов в поддержку
export const fetchSupportRequests = async (
  page: number = 1,
  pageSize: number = 7,
  ordering: string = "",
  filters: Record<string, any> = {}
): Promise<SupportRequestResponse> => {
  const url = `/courses/support-requests/`;
  try {
    const response = await axiosInstance.get(url, {
      params: {
        page,
        page_size: pageSize,
        ordering,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении запросов в поддержку:", error);
    throw error;
  }
};

// Получение запроса в поддержку по ID
export const fetchSupportRequestById = async (
  supportRequestId: number
): Promise<SupportRequestResponse> => {
  const url = `/courses/support-requests/${supportRequestId}/`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении запроса поддержки с ID ${supportRequestId}:`, error);
    throw error;
  }
};

// Создание запроса в поддержку
export const createSupportRequest = async (
  payload: SupportRequestPayload
): Promise<SupportRequestCreateResponse> => {
  const url = `/courses/support-requests/`;

  const formData = new FormData();
  formData.append("question", payload.question);
  formData.append("topic", payload.topic);

  if (payload.material) {
    formData.append("material", payload.material); // Добавляем файл, если он есть
  }

  try {
    const response = await axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании обращения в поддержку:", error);
    throw error;
  }
};

// Удаление запроса в поддержку
export const deleteSupportRequest = async (
  supportRequestId: number
): Promise<void> => {
  const url = `/courses/support-requests/${supportRequestId}/`;

  try {
    await axiosInstance.delete(url);
    console.log(`Обращение с ID ${supportRequestId} успешно удалено.`);
  } catch (error) {
    console.error(`Ошибка при удалении обращения с ID ${supportRequestId}:`, error);
    throw error;
  }
};
