import React, { useState, useEffect, useRef } from "react";
import {
  StatisticsPageContainer,
  HeaderContainer,
  StatsGrid,
  ChartWrapper,
  Title,
  ContentWrapper,
  ModeSwitchContainer,
  ModeButton,
} from "./StatisticsPage.styles";
import Sidebar from "../../components/Sidebar";
import LineChart from "../../components/LineChart";
import StatWindow from "../../components/StatWindow/StatWindow";
import { fetchStatistics } from "../../services/statisticService/statisticService";
import { StatisticResponse } from "../../services/statisticService/statisticService.types";
import { ChartDataPoint } from "./StatisticsPage.types";
import { Toast } from "primereact/toast";

const StatisticsPage: React.FC = () => {
  const [statistics, setStatistics] = useState<StatisticResponse[] | null>(null);
  const [fullData, setFullData] = useState<ChartDataPoint[]>([]);
  const [filteredData, setFilteredData] = useState<ChartDataPoint[]>([]);
  // const [activeUsers, setActiveUsers] = useState<number>(0);
  const [currentStatType, setCurrentStatType] = useState<"ученики" | "доход">("ученики"); // Удален "активные"
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const loadStatistics = async () => {
      try {
        const data = await fetchStatistics();
        setStatistics(data);
        const chartData = mapStatisticsToChartData(data, currentStatType);
        setFullData(chartData);
        setFilteredData(chartData);
        // calculateActiveUsers(data, new Date());
      } catch (error) {
        console.error("Ошибка при загрузке статистики:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки статистики", life: 3000 });
      }
    };

    loadStatistics();
  }, []);

  const mapStatisticsToChartData = (
    stats: StatisticResponse[],
    type: "ученики" | "доход" // Удален "активные"
  ): ChartDataPoint[] => {
    return stats.map((stat) => {
      let value = 0;
      if (type === "ученики") value = stat.total_users;
      if (type === "доход") value = stat.total_income;
      // Удалена логика для "активные"
      return {
        date: stat.created,
        value,
      };
    });
  };

  // Удалена функция calculateActiveUsers

  const handleDateChange = (selectedDate?: Date | null) => {
    if (!selectedDate) {
      setFilteredData(fullData);
      // calculateActiveUsers(statistics || [], new Date());
      return;
    }

    const filtered = fullData.filter((dataPoint) => {
      const date = new Date(dataPoint.date);
      return (
        date.getFullYear() === selectedDate.getFullYear() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getDate() === selectedDate.getDate()
      );
    });

    setFilteredData(filtered);
    // calculateActiveUsers(statistics || [], selectedDate);
  };

  const handleStatTypeChange = (type: "ученики" | "доход") => {
    setCurrentStatType(type);
    const updatedData = mapStatisticsToChartData(statistics || [], type);
    setFullData(updatedData);
    setFilteredData(updatedData);
  };

  return (
    <StatisticsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Статистика</Title>
        </HeaderContainer>
        <StatsGrid>
          <StatWindow
            title="Количество учеников"
            value={
              statistics?.reduce((sum, stat) => sum + stat.total_users, 0) || 0
            }
            onButtonClick={() => handleStatTypeChange("ученики")}
          />
          <StatWindow
            title="Общий доход"
            value={
              statistics?.reduce((sum, stat) => sum + stat.total_income, 0) || 0
            }
            onButtonClick={() => handleStatTypeChange("доход")}
          />
          {/* Удален компонент для активных пользователей */}
        </StatsGrid>
        <ChartWrapper>
          <LineChart
            data={filteredData}
            onDateChange={handleDateChange}
            type={currentStatType}
          />
        </ChartWrapper>
      </ContentWrapper>
    </StatisticsPageContainer>
  );
};

export default StatisticsPage;
