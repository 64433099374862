import styled from "styled-components";

interface DropZoneProps {
  dragOver: boolean;
}

export const DropZone = styled.div<DropZoneProps>`
  border: 1px dashed rgb(114, 82, 220);
  border-radius: 10px;
  background: rgba(114, 82, 220, 0.17);
  opacity: 0.6;

  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 24px;

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  p {
    color: rgb(143, 132, 154);
    font-size: 16px;
    font-weight: 500;
  }
`;

export const InfoText = styled.div`
  font-size: 12px;
  color: #888;
  text-align: center;
  margin-bottom: 10px;
`;

export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
`;

export const FileItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f8fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  position: relative;
  max-width: 150px;
  cursor: pointer;
`;

export const FileIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const FileName = styled.span`
  font-size: 14px;
  color: #333;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveFileButton = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
