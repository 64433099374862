import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { referralService } from '../services/referralService/referralService';

function ReferralHandler() {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('referral_token');

  useEffect(() => {
    if (referralCode) {
      // Сохраняем реферальный код в cookies для дальнейшего использования
      Cookies.set('referralCode', referralCode, {
        sameSite: 'None',
        secure: true,
        expires: 1, // Срок действия 1 день
      });
      console.log('Реферальный код успешно сохранён:', referralCode);

      // Проверяем, был ли переход по данной реферальной ссылке уже учтён
      const clickTracked = Cookies.get(`referralTracked_${referralCode}`);

      if (!clickTracked) {
        // Учитываем переход
        referralService
          .trackClick(referralCode)
          .then(() => {
            console.log('Переход по реферальной ссылке успешно учтён.');
          })
          .catch((error) =>
            console.error('Ошибка при учёте перехода по реферальной ссылке:', error)
          );

        // Устанавливаем cookie, чтобы отметить, что переход был учтён
        Cookies.set(`referralTracked_${referralCode}`, 'true', {
          sameSite: 'None',
          secure: true,
          expires: 30, // Срок действия 30 дней
        });
      } else {
        console.log('Переход по реферальной ссылке уже был учтён для этого пользователя.');
      }
    }
  }, [referralCode]);

  return null;
}

export default ReferralHandler;
