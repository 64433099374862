import React from 'react';
import { ModalContainer, ModalContent, ModalIcon, CloseButton } from './SuccessModal.styles';
import { SuccessModalProps } from './SuccessModal.types';
import successIcon from "../../../assets/icons/successIcon.svg";
import closeIcon from "../../../assets/icons/closeIcon.svg";

const SuccessModal: React.FC<SuccessModalProps> = ({ message, onClose }) => {
  return (
    <ModalContainer>
      <ModalContent>
        <CloseButton onClick={onClose}><img src={closeIcon} alt="close" /></CloseButton>
        <p>{message}</p>
        <ModalIcon src={successIcon} alt="success"></ModalIcon>
      </ModalContent>
    </ModalContainer>
  );
};

export default SuccessModal;
