import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import {
  LessonsPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
  FileLinkContainer,
  BaseLinkItem,
} from "./LessonsPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import Button from "../../components/Buttons/Button";
import IconButton from "../../components/Buttons/IconButton";
import delIcon from "../../assets/icons/delIcon.svg";
import smallFileIcon from "../../assets/icons/smallFileIcon.svg";
import linkIcon from "../../assets/icons/linkIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import DeletedRow from "../../components/DeletedRow";
import RestoredRow from "../../components/RestoredRow";
import { Lesson } from "./LessonsPage.types";
import {
  fetchLessons,
  deleteLesson,
  createLesson,
  updateLesson,
} from "../../services/lessonService/lessonService";
import { fetchLessonCategories } from "../../services/lessonService/lessonService";
import { FileIcon } from "../../components/DragAndDropField/DragAndDropField.styles";
import LessonDetails from "../../components/LessonDetails";
import { LessonData } from "../../services/lessonService/lessonService.types";
import { ConfirmModal, CreateModal } from "../../components/Modals";
import { FileDisplay } from "../../components/FileToDisplay/FileToDisplay.types";
import LessonsButton from "../../components/Buttons/LessonsButton";
import { Toast } from "primereact/toast";

const LessonsPage: React.FC = () => {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [lessonToEdit, setLessonToEdit] = useState<Lesson | null>(null);
  const [lessonToDelete, setLessonToDelete] = useState<Lesson | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(6);
  const [orderBy, setOrderBy] = useState<string>("");
  const [pendingDelete, setPendingDelete] = useState<number | null>(null);
  const [recentlyRestored, setRecentlyRestored] = useState<number | null>(null);
  const [recentlyCreated, setRecentlyCreated] = useState<number | null>(null);
  const [recentlyEdited, setRecentlyEdited] = useState<number | null>(null);
  const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [externalErrors, setExternalErrors] = useState<Record<string, string>>({});


  const deleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [storageFiles, setStorageFiles] = useState(
    [] as FileDisplay[]);

  const courseId = Number(window.location.pathname.split("/")[2]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    loadLessons();
  }, [currentPage, pageSize, orderBy]);

  useEffect(() => {
    loadCategories();
  }, []);


  const handleRemoveStorageFile = (fileId: number) => {
    setStorageFiles((prevFiles) =>
      (prevFiles || []).filter((file) => file.id !== fileId)
    );
  };

  const loadLessons = async () => {
    setLoading(true);
    try {
      const response = await fetchLessons(

        courseId,
        currentPage,
        pageSize,
        orderBy
      );
      setLessons(response.results);
      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки уроков", life: 3000 });
      console.error("Ошибка загрузки уроков:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadCategories = async () => {
    try {
      const categories = await fetchLessonCategories(

        courseId
      );
      setCategories(categories);
    } catch (error) {
      console.error("Ошибка загрузки категорий:", error);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки категорий", life: 3000 });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    handleRowExpand(null);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };
  const handleRowExpand = (rowIndex: number | null) => {
    if (rowIndex !== null) {
      setIsHeaderHidden(true);
      setTimeout(() => {
        setIsTableExpanded(true);
      }, 700);
      setExpandedRowIndex(rowIndex);
    } else {
      setIsTableExpanded(false);
      setTimeout(() => {
        setIsHeaderHidden(false);
      }, 700);
      setExpandedRowIndex(null);
    }
  };

  const getTableRows = () => {
    const rows = lessons.map((lesson) => {
      if (lesson.id === pendingDelete) {
        return {
          title: (
            <DeletedRow
              id={lesson.id}
              onRestore={restoreLessonHandler}
              colSpan={headers.length + 1}
              message={`Урок ${lesson.title} будет удален через 5 секунд.`}
              targetText="Восстановить урок"
            />
          ),
        };
      }
      return {
        order: lesson.order,
        title: lesson.title,
        category: lesson.category_title || "—",
        expandedContent: (
          <LessonDetails
            courseId={courseId}
            lessonId={lesson.id}
            title={lesson?.title || ""}
            order={lesson?.order || 1}
            content={lesson?.content || ""}
            category={lesson?.category_title || ""}
            video_url={lesson?.video_url || ""}
            files_from_storage={lesson?.files_from_storage || []}
            assignments={lesson?.assignments || []}
            onSubmit={(formData) => handleCreateOrEditLesson(formData, { isEditing: true, lessonId: lesson.id })}
            onAssignmentsUpdate={loadLessons}
          />

        ),
        with_assignments: lesson.with_assignments ? (
          <LessonsButton
            href={`/courses/${courseId}/lessons/${lesson.id}/assignments`}
            text="Смотреть"
          />
        ) : (
          <LessonsButton
            href={`/courses/${courseId}/lessons/${lesson.id}/assignments`}
            text="Отсутствуют"
            noLessons={true}
          />
        ),
        files: (
          <FileLinkContainer>
            {lesson.with_link && (
              <BaseLinkItem>
                <FileIcon src={linkIcon} alt="Ссылка" />
                Ссылка
              </BaseLinkItem>
            )}
            {lesson.with_files && lesson.files_from_storage?.length > 0 && (
              <BaseLinkItem>
                <FileIcon src={smallFileIcon} alt="Файл" />
                Файл
              </BaseLinkItem>
            )}
          </FileLinkContainer>
        ),
        edit: (
          <IconButton
            icon={editIcon}
            altText="Редактировать"
            onClick={() => openEditModal(lesson)}
          />
        ),
        delete: (
          <IconButton
            icon={delIcon}
            altText="Удалить"
            onClick={() => {
              setLessonToDelete(lesson);
              setIsDeleteModalOpen(true);
            }}
          />
        ),
      };
    });

    if (recentlyRestored !== null) {
      const restoredIndex = lessons.findIndex(
        (l) => l.id === recentlyRestored
      );
      if (restoredIndex !== -1) {
        rows.splice(restoredIndex + 1, 0, {
          title: (
            <RestoredRow
              colSpan={headers.length + 1}
              message="Урок успешно восстановлен"
            />
          ),
        });
      }
    }

    if (recentlyCreated !== null) {
      const createdIndex = lessons.findIndex((l) => l.id === recentlyCreated);
      if (createdIndex !== -1) {
        rows.splice(createdIndex + 1, 0, {
          title: (
            <RestoredRow
              colSpan={headers.length + 1}
              message="Урок успешно создан"
            />
          ),
        });
      }
    }

    if (recentlyEdited !== null) {
      const editedIndex = lessons.findIndex((l) => l.id === recentlyEdited);
      if (editedIndex !== -1) {
        rows.splice(editedIndex + 1, 0, {
          title: (
            <RestoredRow
              colSpan={headers.length + 1}
              message="Урок успешно отредактирован"
            />
          ),
        });
      }
    }

    return rows;
  };

  const openCreateModal = () => {
    setLessonToEdit(null);
    setIsCreateModalOpen(true);
  };

  const openEditModal = (lesson: Lesson) => {
    setStorageFiles(lesson?.files_from_storage || []);
    setLessonToEdit(lesson);
    setIsCreateModalOpen(true);
  };

  const handleDeleteLesson = () => {
    if (lessonToDelete) {
      const lessonId = lessonToDelete.id;
      setPendingDelete(lessonId);
      setIsDeleteModalOpen(false);
      setLessonToDelete(null);

      deleteTimeoutRef.current = setTimeout(async () => {
        try {
          await deleteLesson(courseId, lessonId);
          setPendingDelete(null);
          loadLessons();
        } catch (error) {
          console.error("Ошибка при удалении урока:", error);
          toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка удаления урока", life: 3000 });
        }
      }, 5000);
    }
  };

  const restoreLessonHandler = (lessonId: number) => {
    if (deleteTimeoutRef.current) {
      clearTimeout(deleteTimeoutRef.current);
      deleteTimeoutRef.current = null;
    }

    setRecentlyRestored(lessonId);
    setPendingDelete(null);

    loadLessons();

    setTimeout(() => setRecentlyRestored(null), 3000);
  };

  const handleCreateOrEditLesson = async (
    formData: Record<string, string | File[] | null | FileDisplay[]>,
    options?: { isEditing?: boolean; lessonId?: number }
  ) => {
    const { isEditing, lessonId } = options || {};

    setExternalErrors({}); // Сброс ошибок
    try {
      let existingFileIdsString = "";

      if (Array.isArray(formData.files_from_storage)) {
        const updatedStorageFiles = formData.files_from_storage as FileDisplay[];
        const existingFileIds = updatedStorageFiles.map((file) => file.id);
        existingFileIdsString = existingFileIds.join(",");
      } else {
        const existingFileIds: number[] = Array.isArray(storageFiles)
          ? (storageFiles as FileDisplay[]).map((file) => file.id)
          : [];
        existingFileIdsString = existingFileIds.join(",");
      }

      const uploadedFiles: File[] = Array.isArray(formData.files)
        ? (formData.files as File[])
        : [];

      const lessonData: LessonData = {
        course: courseId,
        order: Number(formData.order),
        title: formData.title as string,
        category_title: formData.category as string,
        content: formData.content as string,
        video_url: formData.video_url as string,
        files: uploadedFiles,
        file_ids: existingFileIdsString,
      };

      if (isEditing && lessonId) {
        await updateLesson(courseId, lessonId, lessonData);
        setRecentlyEdited(lessonId);
        handleRowExpand(null);
      } else if (lessonToEdit) {
        const lessonId = lessonToEdit.id;
        await updateLesson(courseId, lessonId, lessonData);
        handleRowExpand(null);
        setRecentlyEdited(lessonId);
      } else {
        const createdLesson = await createLesson(courseId, lessonData);
        setRecentlyCreated(createdLesson.id);
      }
      
      await loadCategories();

      setLessonToEdit(null);
      setIsCreateModalOpen(false);
      loadLessons();

      setTimeout(() => {
        setRecentlyCreated(null);
        setRecentlyEdited(null);
      }, 3000);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};

        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }

        setExternalErrors(formattedErrors); // Сохраняем ошибки
      } else {
        console.error("Ошибка сохранения урока:", error);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка сохранения урока",
          life: 3000,
        });
      }
    }
  };

  const headers = [
    { label: "№", key: "order", fieldName: "order" },
    { label: "Название", key: "title", fieldName: "title" },
    { label: "Раздел", key: "category", fieldName: "category", hidden: true },

    { label: "Материал", key: "files", fieldName: "files", hidden: true },
    {
      label: "Домашнее задание",
      key: "with_assignments",
      fieldName: "with_assignments",
      hidden: true,
    },
    { label: "", key: "edit", sortable: false, fieldName: "" },
    { label: "", key: "delete", sortable: false, fieldName: "" },
  ];


  const rowAmountOptions = [
    { label: "6 уроков", value: 6 },
    { label: "12 уроков", value: 12 },
    { label: "18 уроков", value: 18 },
  ];

  return (
    <LessonsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <motion.div
          style={{
            overflow: "hidden", // Скроем содержимое при уменьшении высоты
          }}
          initial={{ height: "auto" }}
          animate={{ height: isHeaderHidden ? 0 : "auto" }}
          transition={{ duration: 0.5 }}
        >
          <HeaderContainer>
            <Title>Мои уроки</Title>
            <ButtonWrapper>
              <Button
                onClick={openCreateModal}
                variant="active"
                width="160px"
                height="50px"
              >
                Новый урок
              </Button>
            </ButtonWrapper>
          </HeaderContainer>
        </motion.div>
        <Table
          headers={headers}
          rows={getTableRows()}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onSort={handleSort}
          rowAmountOptions={rowAmountOptions}
          onRowExpand={handleRowExpand}
          isExpanded={isTableExpanded}
          specialRowKey="title"
          loading={loading}
          expandedRowIndex={expandedRowIndex}
        />
        {isCreateModalOpen && (
          <CreateModal
            title={lessonToEdit ? "Редактировать урок" : "Новый урок"}
            hasDragAndDrop={true}
            fields={[
              {
                name: "order",
                type: "number",
                placeholder: "Номер урока",
                required: true,
              },
              {
                name: "title",
                type: "text",
                placeholder: "Название",
                required: true,
              },
              {
                name: "category",
                type: "selectWithInput",
                placeholder: "Категория",
                options: categories,
                required: true,
              },
              { name: "content", type: "textarea", placeholder: "Описание" },
            ]}
            onSubmit={handleCreateOrEditLesson}
            submitButtonText={lessonToEdit ? "Сохранить" : "Создать"}
            onCancel={() => setIsCreateModalOpen(false)}
            onRemoveFile={handleRemoveStorageFile}
            initialValues={
              lessonToEdit
                ? {
                  order: lessonToEdit.order.toString(),
                  title: lessonToEdit.title,
                  category: lessonToEdit.category_title || "",
                  content: lessonToEdit.content || "",
                  video_url: lessonToEdit.video_url || "",
                }
                : {}
            }
            files_from_storage={lessonToEdit?.files_from_storage || []}
            externalErrors={externalErrors}
          />
        )}
        {isDeleteModalOpen && (
          <ConfirmModal
            isOpen={isDeleteModalOpen}
            title="Удаление урока"
            message={`Вы уверены, что хотите удалить урок "${lessonToDelete?.title}"?`}
            onConfirm={handleDeleteLesson}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
        )}
      </ContentWrapper>
    </LessonsPageContainer>
  );
};

export default LessonsPage;
