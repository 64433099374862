import styled from "styled-components";

export const RestoredRowContainer = styled.div`
  width: 100%;
  padding: 10px 20px;
  background: rgb(86, 189, 116);
  

  display: flex;
  justify-content: left;
  align-items: center;
  adding: 6px 25px 6px 25px;
`;

export const RestoredRowMessage = styled.span`
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 400;
  padding-left: 32px;
`;
