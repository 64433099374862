import axiosInstance from "../axiosConfig";
import { Assignment, AssignmentData, AssignmentResponse } from "./assignmentService.types";

// Получение списка домашних заданий
export const fetchAssignments = async (
  courseId: number,
  lessonId?: number,
  page: number = 1,
  pageSize: number = 10,
  ordering: string = ""
): Promise<AssignmentResponse> => {
  const url = `/courses/lessons/${lessonId}/assignments/`;
  try {
    const response = await axiosInstance.get<AssignmentResponse>(url, {
      params: { page, page_size: pageSize, ordering },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка домашних заданий:", error);
    throw error;
  }
};

// Создание домашнего задания
export const createAssignment = async (
  courseId: number,
  lessonId: number,
  assignmentData: AssignmentData
): Promise<Assignment> => {
  const url = `/courses/lessons/${lessonId}/assignments/`;

  const formData = new FormData();
  formData.append("title", assignmentData.title);
  formData.append("content", assignmentData.content);
  formData.append("lesson", String(lessonId));
  if (assignmentData.video_url) formData.append("video_url", assignmentData.video_url);

  if (assignmentData.files) {
    assignmentData.files.forEach((file) => {
      if (typeof file === "string") {
        formData.append("existing_files", file);
      } else {
        formData.append("files", file);
      }
    });
  }

  try {
    const response = await axiosInstance.post<Assignment>(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании домашнего задания:", error);
    throw error;
  }
};

// Обновление домашнего задания
export const updateAssignment = async (
  courseId: number,
  lessonId: number,
  assignmentId: number,
  assignmentData: AssignmentData
): Promise<Assignment> => {
  const url = `/courses/lessons/${lessonId}/assignments/${assignmentId}/`;

  const formData = new FormData();
  formData.append("title", assignmentData.title);
  formData.append("content", assignmentData.content);
  formData.append("lesson", String(lessonId));
  if (assignmentData.video_url) formData.append("video_url", assignmentData.video_url); else formData.append("video_url", "");

  if (assignmentData.file_ids !== undefined) {
    formData.append("file_ids", assignmentData.file_ids || "");
  }

  if (assignmentData.files) {
    assignmentData.files.forEach((file) => {
      if (typeof file === "string") {
        formData.append("existing_files", file);
      } else {
        formData.append("files", file);
      }
    });
  }

  try {
    const response = await axiosInstance.put<Assignment>(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении домашнего задания:", error);
    throw error;
  }
};

// Удаление домашнего задания
export const deleteAssignment = async (
  courseId: number,
  lessonId: number,
  assignmentId: number
): Promise<void> => {
  const url = `/courses/lessons/${lessonId}/assignments/${assignmentId}/`;

  try {
    await axiosInstance.delete(url);
  } catch (error) {
    console.error("Ошибка при удалении домашнего задания:", error);
    throw error;
  }
};
