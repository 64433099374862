import styled, { css } from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 60%;
  min-width: 500px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;

  h2 {
    color: rgb(31, 10, 53);
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(225, 224, 229);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

export const ModalBody = styled.div`
  padding: 30px;
  padding-bottom: 0;
`;

export const ModalFooter = styled.div`
  padding: 30px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Input = styled.input<{ hasError?: boolean }>`
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid rgba(31, 10, 53, 0.1);
  background: rgb(247, 248, 248);
  scrollbar-width: none;

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid rgb(242, 40, 98);
      background: rgba(114, 82, 220, 0.15);
    `}

  &:focus {
    border: 1px solid rgb(114, 82, 220);
    background: rgba(114, 82, 220, 0.15);
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ff4d4d;
  margin-top: -10px;
  margin-bottom: 5px;
`;


export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;