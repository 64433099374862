import React, { useMemo, useState, useRef } from 'react';
import { LoginFormProps } from "./LoginPage.types";
import { Container, Logo } from "./LoginPage.styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { FormModal } from '../../components/Modals';
import { getAuthTokens } from '../../services/authService/authService';
import { Toast } from 'primereact/toast';
import { useAuth } from '../../context/AuthContext';

const LoginPage: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation(); 

  const navigate = useNavigate();
  const [externalErrors, setExternalErrors] = useState<Record<string, string>>({});
  const memoizedExternalErrors = useMemo(() => externalErrors, [externalErrors]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const { login } = useAuth();
  
  // Конфигурация полей для формы
  const fields = [
    {
      name: "email",
      type: "email",
      placeholder: t("auth.email"),
      required: true,
      width: "369px",
      height: "45px",
      validate: (value: string) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : t("auth.emailError"),
    },
    {
      name: "password",
      type: "password",
      width: "369px",
      height: "45px",
      placeholder: t("auth.password"),
      required: true,
      validate: (value: string) =>
        value.trim().length > 0 ? null : t("auth.passwordError"),
    },
  ];

  // Ссылки для нижней части формы
  const footerLinks = [
    {
      text: t("auth.createAccount"),
      href: "/signup",
      variant: "primary", // Фиолетовый стиль
    },
    {
      text: t("auth.passwordSos"),
      href: "/reset-password",
      variant: "secondary", // Серый стиль
    },
  ];

  // Обработка отправки формы
  const handleSubmit = async (formData: Record<string, string>) => {
    setIsLoading(true);
    setExternalErrors({});
    try {
      await getAuthTokens(formData.email, formData.password);
      login();
      navigate('/main');
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};

        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }

        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка авторизации:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка авторизации", life: 3000 });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Toast ref={toast} />
      <Logo>LISRENT</Logo>
      <FormModal
        title={t("auth.in")}
        fields={fields}
        onSubmit={handleSubmit}
        hasBackdrop={false}
        footerLinks={footerLinks}
        submitButtonText={t("auth.login")}
        externalErrors={memoizedExternalErrors}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default LoginPage;
