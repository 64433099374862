import styled from "styled-components";

export const TariffCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 22px;
  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;
  min-height: 100%;

  box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06),
    0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);

  button {
    margin: 0;
  }
 
`;

export const TariffTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 49px;
  color: rgb(31, 10, 53);
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 16px;

`;

export const TariffInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const TariffExpiration = styled.span`
    color: rgb(185, 185, 185);
    font-size: 16px;
    font-weight: 600;
`;

export const TariffDescriptionContainer = styled.div`
  margin-bottom: 20px;
  text-align: left;
  margin-bottom: auto;
`;


export const TariffDescription = styled.p`
color: rgb(31, 10, 53);
font-size: 16px;
font-weight: 500;
`;


export const TariffPrice = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #ff4081;
  margin: 8px 0;
`;
