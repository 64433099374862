import React from "react";
import {
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
  ProfilePageContainer,
  TariffCardsContainer,
} from "./ProfilePage.styles";
import Button from "../../components/Buttons/Button";
import Sidebar from "../../components/Sidebar";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import TariffCard from "../../components/TariffCard/TariffCard";

const tariffs = [
  {
    title: "Пользовательский",
    description: [
      "Образовательная платформа",
      "Реферальная система",
      "Неограниченное количество по созданию: Приказов о зачислении и об отчислении учеников",
      "Неограниченное количество по созданию: Договоров об оказании платных образовательных услуг",
      "Неограниченное количество по созданию: Ученических договоров",
      "Неограниченное количество по созданию: Оферты",
    ],
    price: "3190₽",
    expirationDate: "12.12.2024",
    isActive: true,
    buttonVariant: "pink",
  },
  {
    title: "Оптимальный",
    description: [
      "Образовательная платформа",
      "Реферальная система",
      "Неограниченное количество по созданию: Приказов о зачислении и об отчислении учеников",
      "Неограниченное количество по созданию: Договоров об оказании платных образовательных услуг",
      "Неограниченное количество по созданию: Ученических договоров",
      "Неограниченное количество по созданию: Оферты",
      "Неограниченное количество по созданию: Учебных программ ДПО/ПО/ДО",
      "Неограниченное количество по созданию: Приказов об утверждении образовательных программ",
    ],
    price: "3790₽",
    expirationDate: "12.12.2024",
    isActive: false,
    buttonVariant: "default",
  },
  {
    title: "Премиум",
    description: [
      "Образовательная платформа",
      "Реферальная система",
      "Неограниченное количество по созданию: Приказов о зачислении и об отчислении учеников",
      "Неограниченное количество по созданию: Договоров об оказании платных образовательных услуг",
      "Неограниченное количество по созданию: Ученических договоров",
      "Неограниченное количество по созданию: Оферты",
      "Неограниченное количество по созданию: Учебных программ ДПО/ПО/ДО",
      "Неограниченное количество по созданию: Приказов об утверждении образовательных программ",
      "Неограниченное количество по созданию: Договоров на оплату образовательных услуг социальным контрактом",
      "Неограниченное количество по созданию: Договоров на оплату образовательных услуг материнским капиталом",
    ],
    price: "6290₽",
    expirationDate: "12.12.2024",
    isActive: false,
    buttonVariant: "default",
  },
];



const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("user_id");
    localStorage.clear();
    logout();
    navigate("/login");
  };

  return (
    <ProfilePageContainer>
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Личный кабинет</Title>
          <ButtonWrapper>
            <Button
              variant="red"
              width="120px"
              height="50px"
              onClick={handleLogout}
            >
              Выход
            </Button>
          </ButtonWrapper>
        </HeaderContainer>
       <TariffCardsContainer>
          {tariffs.map((tariff, index) => (
            <TariffCard
              key={index}
              title={tariff.title}
              description={tariff.description}
              isActive={tariff.isActive}
              expirationDate={tariff.expirationDate}
              price={tariff.price}
              onClick={() => alert(`Вы выбрали тариф: ${tariff.title}`)}
            />
          ))}
        </TariffCardsContainer>

      </ContentWrapper>
    </ProfilePageContainer>
  );
};

export default ProfilePage;
