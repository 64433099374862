import styled from "styled-components";

export const StatisticsPageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  height: calc(100vh - 24px); /* Занимает всю высоту экрана с учетом отступов */
  box-shadow:
    0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  overflow-x: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Адаптивная сетка */
  gap: 24px;
`;

export const ChartWrapper = styled.div`
  margin-top: 32px;
  padding: 0; 
  background: white;
  border-radius: 16px;
  height: calc(100% - 32px);
  box-shadow:
    0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);

  .recharts-wrapper {
    height: 100%;
  }
`;


export const ModeSwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ModeButton = styled.button<{ isActive: boolean }>`
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? "#724EDC" : "#e0e0e0")};
  color: ${({ isActive }) => (isActive ? "white" : "#333")};
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "#5a3bba" : "#d0d0d0")};
  }
`;
