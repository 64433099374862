import styled from "styled-components";

export const LessonDetailsContainer = styled.div`
  padding: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
`;

export const LessonDescription = styled.p`
  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 400;
`;

export const LessonCategory = styled.p`
  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 400;
`;

export const LessonLink = styled.a`
  color: rgb(114, 82, 220);
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const LessonFiles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgb(235, 235, 235);
    width: 87px;
    height: 87px;
    text-decoration: none;
    padding: 12px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 60px;
  }

  &:hover {
  }
`;

export const FileIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const AddMaterialLink = styled.span`
  display: block;
  text-align: center;
  color: rgb(114, 82, 220);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  padding: 12px 12px 12px 0px;
  border: 1px solid rgb(114, 82, 220);
  border-radius: 12px;
  margin-bottom: 40px;

  &:hover {
  }
`;

export const ActionIconsWrapper = styled.div<{ isEditing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  border: 1px solid ${({ isEditing }) => (isEditing ? "rgb(114, 82, 220)" : "rgba(31, 10, 53, 0.1)")};
  border-radius: 16px;
  padding: 20px;
  background-color: ${({ isEditing }) => (isEditing ? "rgba(114, 82, 220, 0.05)" : "transparent")};
  transition: all 0.3s ease;

  img {
    cursor: pointer;
    margin-left: 10px;
    width: 20px;
    height: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
`;


export const AddHomeworkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 22px;
`;


export const EditableInput = styled.input`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: rgb(31, 10, 53);
  border: 1px solid rgba(31, 10, 53, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  outline: none;
  border: none;
  background: none;

`;


export const FileDisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;


export const DropArea = styled.div`
  border: 2px dashed rgba(114, 82, 220, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  color: rgba(31, 10, 53, 0.7);
  cursor: pointer;

  &:hover {
    background-color: rgba(114, 82, 220, 0.05);
  }
`;

export const DragAndDropArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px dashed rgba(114, 82, 220, 0.5);
  border-radius: 12px;
  background-color: rgba(114, 82, 220, 0.05);
  color: rgb(114, 82, 220);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(114, 82, 220, 0.1);
  }

  p {
    margin: 0;
  }
`;
