import React from "react";
import { LessonsButtonProps } from "./LessonsButton.types";
import {
  ButtonContainer,
  ButtonIcon,
  ButtonText,
  StyledLink,
} from "./LessonsButton.styles";
import purpleEyeIcon from "../../../assets/icons/purpleEyeIcon.svg";

const LessonsButton: React.FC<LessonsButtonProps> = ({ href, text, noLessons }) => {
  return (
    // <StyledLink to={noLessons ? "#" : href}>
    <StyledLink to={noLessons ? href : href}>
      <ButtonContainer noLessons={noLessons}>
        {!noLessons && <ButtonIcon src={purpleEyeIcon} alt="eyeIcon" />}
        <ButtonText>{text || "Смотреть"}</ButtonText>
      </ButtonContainer>
    </StyledLink>
  );
};

export default LessonsButton;
