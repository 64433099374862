import React, { useEffect, useRef, useState } from "react";
import {
  AssignmentsWrapper,
  AssignmentsTitle,
  AssignmentsDescription,
  AssignmentsLink,
  FilesList,
  ButtonWrapper,
  ActionButtonsWrapper,
  Container,
  AssignmentContentWrapper,
} from "./AssignmentsContainer.styles";
import { Assignment } from "../../services/assignmentService/assignmentService.types";
import FileToDisplay from "../FileToDisplay/FileToDisplay";
import Button from "../Buttons/Button";
import IconButton from "../Buttons/IconButton";
import delIcon from "../../assets/icons/delIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import { CreateModal, ConfirmModal } from "../../components/Modals";
import { FileDisplay } from "../../components/FileToDisplay/FileToDisplay.types";
import {
  createAssignment,
  fetchAssignments,
  updateAssignment,
  deleteAssignment,
} from "../../services/assignmentService/assignmentService";
import { Toast } from "primereact/toast";

const AssignmentsContainer: React.FC<{ courseId: number; lessonId: number }> = ({
  courseId,
  lessonId,
}) => {
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [assignmentToEdit, setAssignmentToEdit] = useState<Assignment | null>(null);
  const [assignmentToDelete, setAssignmentToDelete] = useState<Assignment | null>(null);
  const [storageFiles, setStorageFiles] = useState<FileDisplay[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useRef<Toast>(null);

  const loadAssignments = async () => {
    setLoading(true);
    try {
      const response = await fetchAssignments(courseId, lessonId);
      setAssignments(response.results);
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки заданий", life: 3000 });
      console.error("Ошибка загрузки заданий:", error);
      setAssignments([]);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    loadAssignments();
  }, []);

  const handleCreateOrEditAssignment = async (
    formData: Record<string, string | File[] | null | FileDisplay[]>,
    isEditing: boolean
  ) => {
    const title = typeof formData.title === "string" ? formData.title : "";
    const content = typeof formData.content === "string" ? formData.content : "";
    const video_url = typeof formData.video_url === "string" ? formData.video_url : "";
    const uploadedFiles: File[] = Array.isArray(formData.files) ? (formData.files as File[]) : [];
    const existingFileIdsString = storageFiles.map((file) => file.id).join(",");

    try {
      const assignmentData = {
        lesson: lessonId,
        title,
        content,
        video_url,
        files: uploadedFiles,
        file_ids: existingFileIdsString,
      };

      if (isEditing && assignmentToEdit) {
        await updateAssignment(
          courseId,
          lessonId,
          assignmentToEdit.id,
          assignmentData
        );
        console.log("Задание успешно обновлено");
      } else {
        await createAssignment(courseId, lessonId, assignmentData);
        console.log("Задание успешно создано");
      }

      loadAssignments();
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка сохранения задания", life: 3000 });
      console.error("Ошибка при сохранении задания:", error);
    } finally {
      setIsCreateModalOpen(false);
      setAssignmentToEdit(null);
      setStorageFiles([]);
    }
  };

  const handleDeleteAssignment = async () => {
    if (!assignmentToDelete) return;

    try {
      await deleteAssignment(courseId, lessonId, assignmentToDelete.id);
      console.log("Задание успешно удалено");

      setAssignments((prev) => prev.filter((assignment) => assignment.id !== assignmentToDelete.id));

      await loadAssignments();
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка удаления задания", life: 3000 });
      console.error("Ошибка при удалении задания:", error);
    } finally {
      setIsDeleteModalOpen(false);
      setAssignmentToDelete(null);
    }
  };


  const handleRemoveStorageFile = (fileId: number) => {
    setStorageFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
  };

  return (
    <AssignmentsWrapper>
      <Toast ref={toast} />
      {loading ? (
        <p>Загрузка...</p>
      ) : assignments.length > 0 ? (
        assignments.map((hw) => (
          <Container key={hw.id}>
            <AssignmentContentWrapper>
              <AssignmentsTitle>{hw.title}</AssignmentsTitle>
              <AssignmentsDescription>{hw.content}</AssignmentsDescription>
              {hw.video_url && (
                <AssignmentsLink href={hw.video_url} target="_blank" rel="noopener noreferrer">
                  Ссылка на материал
                </AssignmentsLink>
              )}
              <FilesList>
                {hw.files_from_storage.length > 0 && (
                  <FileToDisplay files_from_storage={hw.files_from_storage} />
                )}
              </FilesList>
            </AssignmentContentWrapper>

            <ActionButtonsWrapper>
              <IconButton
                icon={editIcon}
                altText="Редактировать"
                onClick={() => {
                  setAssignmentToEdit(hw);
                  setStorageFiles(hw.files_from_storage);
                  setIsCreateModalOpen(true);
                }}
              />
              <IconButton
                icon={delIcon}
                altText="Удалить"
                onClick={() => {
                  setAssignmentToDelete(hw);
                  setIsDeleteModalOpen(true);
                }}
              />
            </ActionButtonsWrapper>
          </Container>


        ))
      ) : (
        <p>Домашнее задание отсутствует</p>
      )}
      <ButtonWrapper>
        <Button
          onClick={() => {
            setAssignmentToEdit(null);
            setIsCreateModalOpen(true);
          }}
          variant="flatNotFilled"
        >
          Добавить задание
        </Button>
      </ButtonWrapper>

      {/* Модальное окно создания/редактирования */}
      {isCreateModalOpen && (
        <CreateModal
          title={assignmentToEdit ? "Редактировать задание" : "Новое задание"}
          hasDragAndDrop={true}
          files_from_storage={storageFiles}
          onRemoveFile={handleRemoveStorageFile}
          submitButtonText={assignmentToEdit ? "Сохранить" : "Создать"}
          fields={[
            { name: "title", type: "text", placeholder: "Название", required: true },
            { name: "content", type: "textarea", placeholder: "Описание", required: true },
          ]}
          onSubmit={(formData) => handleCreateOrEditAssignment(formData, !!assignmentToEdit)}
          onCancel={() => {
            setIsCreateModalOpen(false);
            setStorageFiles([]);
          }}
          initialValues={
            assignmentToEdit
              ? {
                title: assignmentToEdit.title,
                content: assignmentToEdit.content,
                video_url: assignmentToEdit.video_url || "",
              }
              : {}
          }
        />
      )}

      {/* Модальное окно подтверждения удаления */}
      {isDeleteModalOpen && (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          title="Удаление задания"
          message={`Вы уверены, что хотите удалить задание "${assignmentToDelete?.title}"?`}
          onConfirm={handleDeleteAssignment}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </AssignmentsWrapper>
  );
};

export default AssignmentsContainer;
