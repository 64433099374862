import React, { useState, useEffect, useRef } from "react";
import {
  SupportRequestPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
} from "./SupportRequestPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import {
  createSupportRequest,
  fetchSupportRequests,
  deleteSupportRequest,
} from "../../services/supportRequestService/supportRequestService";
import Button from "../../components/Buttons/Button";
import { CreateModal } from "../../components/Modals";
import { BaseLinkItem, FileLinkContainer } from "../LessonsPage/LessonsPage.styles";
import StatusBadge from "../../components/StatusBadge";
import LessonsButton from "../../components/Buttons/LessonsButton";
import smallFileIcon from "../../assets/icons/smallFileIcon.svg";
import { FileIcon } from "../../components/DragAndDropField/DragAndDropField.styles";
import { SupportRequest } from "./supportRequestService.types";

import delIcon from "../../assets/icons/delIcon.svg";
import DeletedRow from "../../components/DeletedRow";
import RestoredRow from "../../components/RestoredRow";
import IconButton from "../../components/Buttons/IconButton";
import SupportResponsePage from "../SupportResponsePage/SupportResponsePage";
import { Toast } from "primereact/toast";

const SupportRequestPage: React.FC = () => {
  const [requests, setRequests] = useState<SupportRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pendingDelete, setPendingDelete] = useState<number | null>(null);
  const [recentlyRestored, setRecentlyRestored] = useState<number | null>(null);
  const [recentlyCreated, setRecentlyCreated] = useState<number | null>(null);
  const deleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    loadSupportRequests();
  }, [currentPage, pageSize, orderBy]);

  const loadSupportRequests = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchSupportRequests(
        currentPage,
        pageSize,
        orderBy
      );
      setRequests(response.results);
      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (err) {
      console.error("Ошибка при загрузке запросов поддержки:", err);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки запросов поддержки", life: 3000 });
      setError("Не удалось загрузить запросы поддержки.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const handleDeleteRequest = (requestId: number) => {
    setPendingDelete(requestId);

    deleteTimeoutRef.current = setTimeout(async () => {
      try {
        await deleteSupportRequest(requestId);
        setPendingDelete(null);
        loadSupportRequests();
      } catch (error) {
        console.error("Ошибка при удалении обращения:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка удаления обращения", life: 3000 });
      }
    }, 5000);
  };

  const restoreRequestHandler = (requestId: number) => {
    if (deleteTimeoutRef.current) {
      clearTimeout(deleteTimeoutRef.current);
      deleteTimeoutRef.current = null;
    }
    setPendingDelete(null);
    setRecentlyRestored(requestId);

    setTimeout(() => setRecentlyRestored(null), 3000);
  };

  const handleModalSubmit = async (data: Record<string, string | File[] | null>) => {
    try {
      const payload = {
        question: data.question as string,
        topic: data.topic as string,
        material:
          Array.isArray(data.files) && data.files.length > 0 ? data.files[0] : null,
      };

      const response = await createSupportRequest(payload);
      setIsModalOpen(false);
      loadSupportRequests();
      setRecentlyCreated(response.id);

      setTimeout(() => setRecentlyCreated(null), 3000);
    } catch (err) {
      console.error("Ошибка при отправке обращения:", err);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка отправки обращения", life: 3000 });
    }
  };

  const headers = [
    { label: "Обновлено", key: "updated_at", fieldName: "updated_at" },
    { label: "Вопрос", key: "question", fieldName: "question" },
    { label: "Тема обращения", key: "topic", fieldName: "topic" },
    { label: "Материал к вопросу", key: "material", fieldName: "material" },
    { label: "Ответ", key: "response_status", fieldName: "response_status" },
    { label: "Содержание ответа", key: "response_content", fieldName: "response_content" },
    { label: "", key: "delete", sortable: false, fieldName: "" },
  ];

  const rows = requests.map((request) => {
    if (request.id === pendingDelete) {
      return {
        question: (
          <DeletedRow
            id={request.id}
            onRestore={restoreRequestHandler}
            colSpan={7}
            message={`Обращение "${request.question}" будет удалено через 5 секунд.`}
            targetText="Восстановить обращение"
          />
        ),
      };
    }

    return {
      updated_at: new Date(request.updated_at).toLocaleString("ru", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }),
      question: request.question,
      topic: request.topic,
      material: (
        <FileLinkContainer
          onClick={() => {
            if (request.material) {
              window.open(request.material, "_blank");
            } else {
              console.error("URL для файла отсутствует");
              toast.current?.show({ severity: "error", summary: "Ошибка", detail: "URL для файла отсутствует", life: 3000 });
            }
          }}
          style={{ cursor: request.material ? "pointer" : "default" }}
        >
          {request.material && (
            <BaseLinkItem>
              <FileIcon src={smallFileIcon} alt="Файл" />
              Файл
            </BaseLinkItem>
          )}
        </FileLinkContainer>
      ),
      response_status: (
        <StatusBadge
          text={request.response_status ? "Получен" : "Ожидается"}
          variant={request.response_status ? "green" : "red"}
        />
      ),
      response_content: request.response_status ? (
        <LessonsButton
          href={`/support-response/${request.id}`}
          text="Смотреть"
        />
      ) : (
        <LessonsButton href="#" text="Нет ответа" noLessons={true} />
      ),
      delete: (
        <IconButton
          icon={delIcon}
          altText="Удалить"
          onClick={() => handleDeleteRequest(request.id)}
        />
      ),
    };
  });

  return (
    <SupportRequestPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Поддержка</Title>
          <ButtonWrapper>
            <Button
              variant="active"
              width="160px"
              height="50px"
              onClick={() => setIsModalOpen(true)}
            >
              Задать вопрос
            </Button>
          </ButtonWrapper>
        </HeaderContainer>
          <Table
            headers={headers}
            rows={rows}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            onSort={handleSort}
            rowAmountOptions={[
              { label: "7 запросов", value: 7 },
              { label: "15 запросов", value: 15 },
              { label: "20 запросов", value: 20 },
            ]}
            specialRowKey="question"
            loading={loading}
          />
        {isModalOpen && (
          <CreateModal
            title="Поддержка"
            fields={[
              {
                name: "topic",
                type: "text",
                placeholder: "Тема обращения",
                required: true,
              },
              {
                name: "question",
                type: "textarea",
                placeholder: "Вопрос",
                required: true,
              },
            ]}
            hasDragAndDrop={true}
            onSubmit={handleModalSubmit}
            onCancel={() => setIsModalOpen(false)}
            disableToggleSwitch={true}
            submitButtonText={"Задать вопрос"}
            maxFiles={1}
          />
        )}
      </ContentWrapper>
    </SupportRequestPageContainer>
  );
};

export default SupportRequestPage;
