import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormModal } from "../../components/Modals";
import { Field } from "../../components/Modals/FormModal/FormModal.types";
import { createReferral, registerUser, sendVerificationCode } from "../../services/authService/authService";
import Cookies from "js-cookie";
import SuccessModal from "../../components/Modals/SuccessModal";
import { Toast } from "primereact/toast";

const RegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState<Field[]>([
    {
      name: "organization_name",
      type: "text",
      placeholder: "Организация",
      required: true,
      width: "369px",
    },
    {
      name: "firstName",
      type: "text",
      placeholder: "Имя",
      required: true,
      width: "369px",
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Фамилия",
      required: true,
      width: "369px",
    },
    {
      name: "email",
      type: "email",
      placeholder: "Почта",
      required: true,
      width: "369px",
      validate: (value: string) =>
        !/\S+@\S+\.\S+/.test(value) ? "Неправильный формат почты" : null,
    },
    {
      name: "password",
      type: "password",
      placeholder: "Пароль",
      required: true,
      width: "369px",
      validate: (value: string) =>
        value.length < 6 ? "Пароль должен содержать не менее 6 символов" : null,
    },
    {
      name: "confirmPassword",
      type: "password",
      placeholder: "Повторите пароль",
      required: true,
      width: "369px",
      validate: (value: string) =>
        value.length < 6 ? "Пароль должен содержать не менее 6 символов" : null,
    },
  ]);

  const [isCodeSent, setIsCodeSent] = useState(false);
  const [externalErrors, setExternalErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const memoizedExternalErrors = useMemo(() => externalErrors, [externalErrors]);
  const toast = useRef<Toast>(null);

  const footerLinks = [
    { text: "Войти в аккаунт", href: "/login", variant: "primary" },
    { text: "Не помню почту", href: "/forgot-email", variant: "secondary" },
  ];

  const handleSendCode = async (email: string) => {
    setIsLoading(true);
    try {
      await sendVerificationCode(email, "registration");
      setIsCodeSent(true);
      setFields((prevFields) => [
        ...prevFields,
        {
          name: "verification_code",
          type: "text",
          placeholder: "Код из письма",
          required: true,
          width: "369px",
        },
      ]);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};

        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }

        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка при отправке кода:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка отправки кода", life: 3000 });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (formData: Record<string, string>) => {
    setIsLoading(true);
    try {
      if (!isCodeSent) {
        await handleSendCode(formData.email);
      } else {
        const { confirmPassword, ...dataToSubmit } = formData;
        const response = await registerUser(dataToSubmit, formData.confirmPassword);
        const referralCode = Cookies.get("referralCode") || "";

        await createReferral(response?.data?.id, referralCode);

        setShowSuccessModal(true); // Показываем сообщение об успешной регистрации
        setTimeout(() => {
          navigate("/login"); // Редирект через 2 секунды
        }, 2000);
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};

        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }

        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка при регистрации:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка регистрации", life: 3000 });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <Toast ref={toast} />
      <FormModal
        title="Регистрация"
        fields={fields}
        onSubmit={handleSubmit}
        footerLinks={footerLinks}
        submitButtonText={isCodeSent ? "Зарегистрироваться" : "Отправить код"}
        externalErrors={memoizedExternalErrors}
        isLoading={isLoading}
      />
      {showSuccessModal && (
        <SuccessModal
          message="Регистрация прошла успешно!"
          onClose={() => {
            setShowSuccessModal(false);
            navigate("/login"); // Перенаправление при закрытии окна
          }}
        />
      )}
    </>
  );
};

export default RegistrationPage;
