import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  TableContainer,
  StyledTable,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  TableHeadCell,
  ExpandButton,
  ExpandIcon,
  LoadingContainer, // Новый стиль для загрузки
} from "./Table.styles";
import Paginator from "../Paginator";
import { TableProps } from "./Table.types";
import { PaginatorWrapper } from "./Table.styles";
import { ProgressSpinner } from "primereact/progressspinner";
import sortArrow from "../../assets/icons/sortArrow.svg";
import dropDownArrowIcon from "../../assets/icons/dropDownArrowIcon.svg";

const Table: React.FC<TableProps> = ({
  headers,
  rows,
  currentPage,
  onSort,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  rowAmountOptions,
  onRowExpand,
  isExpanded,
  loading,
  minHeight = "115px",
  specialRowKey = "title",
  expandedRowIndex,
}) => {
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());


  useEffect(() => {
    if (typeof expandedRowIndex === 'number') {
      setExpandedRows(new Set([expandedRowIndex]));
    } else {
      setExpandedRows(new Set());
    }
  }, [expandedRowIndex]);

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      const isExpanded = newExpandedRows.has(index);

      if (isExpanded) {
        newExpandedRows.delete(index);
        onRowExpand?.(null);
      } else {
        newExpandedRows.clear();
        newExpandedRows.add(index);
        onRowExpand?.(index);
      }

      return newExpandedRows;
    });
  };

  const hasExpandableRows = rows.some((row) => row.expandedContent);

  return (
    <TableContainer isExpanded={isExpanded} minHeight={minHeight}>
      <StyledTable>
        <TableHeader>
          <tr>
            {headers.map((header, index) => (
              <th key={index} style={{ width: header.width }}>
              <TableHeadCell
                key={index} 
                onClick={
                  header.sortable !== false
                    ? () => onSort?.(header.fieldName || "")
                    : undefined
                }
                style={{
                  cursor: header.sortable === false ? "default" : "pointer",
                  width: headers[index].width,
                }}
              >
                {header.sortable !== false && header.key && (
                  <img src={sortArrow} alt="Sort" />
                )}
                <span>{header.label}</span>
              </TableHeadCell>
            </th>
            ))}
            {hasExpandableRows && <th> </th>}
          </tr>
        </TableHeader>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={headers.length + 1}>
                <LoadingContainer>
                  <ProgressSpinner style={{ width: "50px", height: "50px", color: "#6A0DAD" }} />
                </LoadingContainer>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                {row[specialRowKey]?.props?.colSpan ? (
                  row[specialRowKey]
                ) : (
                  <>
                    <TableRow isExpanded={expandedRows.has(rowIndex)}>
                      {headers.map((header, colIndex) => (
                        <TableCell
                          key={colIndex}
                          isHidden={
                            expandedRows.has(rowIndex) &&
                            header.hidden === true &&
                            !row.isDeleted
                          }
                        >
                          <div className="content">
                            {header.key ? row[header.key] : null}
                          </div>
                        </TableCell>
                      ))}
                      {hasExpandableRows && (
                        <TableCell>
                          {row.expandedContent && (
                            <ExpandButton
                              onClick={() => toggleRow(rowIndex)}
                              isExpanded={expandedRows.has(rowIndex)}
                            >
                              <ExpandIcon
                                src={dropDownArrowIcon}
                                alt="Expand"
                                isExpanded={expandedRows.has(rowIndex)}
                              />
                            </ExpandButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>

                    {expandedRows.has(rowIndex) && row.expandedContent && (
                      <TableRow>
                        <TableCell />
                        <TableCell colSpan={headers.length + 1}>
                          {row.expandedContent}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </React.Fragment>
            ))
          )}
        </TableBody>
      </StyledTable>
      {totalPages > 1 && (
      <PaginatorWrapper>
        <Paginator
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowAmountOptions={rowAmountOptions}
        />
      </PaginatorWrapper>
    )}
    </TableContainer>
  );
};

export default Table;
