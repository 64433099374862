import React from "react";
import { Container, Content, ErrorCode, ErrorMessage, HomeLink } from "./NotFoundPage.styles";

const NotFoundPage = () => {
  return (
    <Container>
      <Content>
        <ErrorCode>404</ErrorCode>
        <ErrorMessage>Страница не найдена</ErrorMessage>
        <HomeLink href="/">На главную</HomeLink>
      </Content>
    </Container>
  );
};

export default NotFoundPage;
                      