import React, { useState, useRef, useEffect } from 'react';
import {
  DropdownContainer,
  DropdownInput,
  DropdownList,
  DropdownItem,
  DropdownIcon,
} from './DropdownWithInput.styles';
import { DropdownWithInputProps } from './DropdownWithInput.types';

const DropdownWithInput: React.FC<DropdownWithInputProps> = ({
  options,
  placeholder = "Выберите или введите значение",
  onChange,
  value = "",
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(userInput.toLowerCase())
    );
    setFilteredOptions(filtered);

    onChange(userInput);
  };

  const handleOptionClick = (option: string) => {
    setInputValue(option);
    onChange(option);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownInput
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setIsDropdownOpen(true)}
      />
      <DropdownIcon isOpen={isDropdownOpen} onClick={toggleDropdown} />
      {isDropdownOpen && (
        <DropdownList>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <DropdownItem key={index} onClick={() => handleOptionClick(option)}>
                {option}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem>Нет совпадений</DropdownItem>
          )}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default DropdownWithInput;
