import React, { useState, useEffect } from "react";

import editIcon from "../../assets/icons/editIcon.svg";
import { LessonDetailsProps } from "./LessonDetails.types";
import {
  ActionIconsWrapper,
  LessonDetailsContainer,
  ButtonWrapper,
  EditableInput,
} from "./LessonDetails.styles";
import Button from "../Buttons/Button";
import AssignmentsContainer from "../AssignmentsContainer";
import IconButton from "../Buttons/IconButton";
import AutoResizableTextarea from "../AutoResizableTextarea/AutoResizableTextarea";
import { FileDisplay } from "../FileToDisplay/FileToDisplay.types";
import DragAndDropField from "../DragAndDropField/DragAndDropField";


const LessonDetails: React.FC<LessonDetailsProps> = ({
  title,
  order,
  content,
  category,
  video_url,
  files_from_storage,
  assignments,
  courseId,
  lessonId,
  onSubmit,
  onAssignmentsUpdate
}) => {
  const [isContentEditable, setIsContentEditable] = useState<boolean>(false);
  const [isCategoryEditable, setIsCategoryEditable] = useState<boolean>(false);
  const [isVideoEditable, setIsVideoEditable] = useState<boolean>(false);
  const [AssignmentsToDelete, setAssignmentsToDelete] = useState<number | null>(null);

  const [contentValue, setContentValue] = useState<string>(content);
  const [categoryValue, setCategoryValue] = useState<string>(category || "");
  const [videoURLValue, setVideoURLValue] = useState<string>(video_url || "");
  const [storageFiles, setStorageFiles] = useState<FileDisplay[]>(files_from_storage || []);
  const [newFiles, setNewFiles] = useState<File[]>([]);


  useEffect(() => {
    if (video_url) {
      setVideoURLValue(video_url);
    }
  }, [video_url]);

  const handleSave = () => {
    const dataToSubmit: Record<string, string | File[] | FileDisplay[] | null> = {
      title,
      order: String(order),
      description: contentValue,
      category: categoryValue,
      video_url: videoURLValue,
      files_from_storage: storageFiles, 
      files: newFiles, 
    };

    onSubmit(dataToSubmit, { isEditing: true, lessonId: order });
  };

  const handleFileSelected = (files: File[] | null) => {
    setNewFiles(files || []);
  };
  const handleRemoveFile = (fileId: number) => {
    setStorageFiles((prev) => prev.filter((file) => file.id !== fileId));
  };

  return (
    <LessonDetailsContainer>
      {/* Описание */}
      <ActionIconsWrapper isEditing={isContentEditable}>
        <AutoResizableTextarea
          value={contentValue}
          disabled={!isContentEditable}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setContentValue(e.target.value)
          }
        />
        <div>
          <IconButton
            icon={editIcon}
            altText="Редактировать"
            onClick={() => setIsContentEditable((prev) => !prev)}
          />
        </div>
      </ActionIconsWrapper>

      {/* Категория */}
      <ActionIconsWrapper isEditing={isCategoryEditable}>
        <EditableInput
          type="text"
          value={categoryValue}
          disabled={!isCategoryEditable}
          onChange={(e) => setCategoryValue(e.target.value)}
        />
        <div>
          <IconButton
            icon={editIcon}
            altText="Редактировать"
            onClick={() => setIsCategoryEditable((prev) => !prev)}
          />
        </div>
      </ActionIconsWrapper>

      <ActionIconsWrapper isEditing={isVideoEditable}>
        {isVideoEditable ? (
          <EditableInput
            type="text"
            value={videoURLValue}
            disabled={!isVideoEditable}
            onChange={(e) => setVideoURLValue(e.target.value)}
          />
        ) : (
          <a
            href={videoURLValue || "#"}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "rgba(114, 82, 220, 1)",
              fontWeight: 500,
              fontSize: "18px",
              textDecoration: "none", 
              cursor: videoURLValue ? "pointer" : "default",
            }}
            onClick={(e) => {
              if (!videoURLValue) e.preventDefault(); 
            }}
          >
            {videoURLValue ? "Ссылка на материал" : "Добавьте ссылку на материал"}
          </a>
        )}
        <div>
          <IconButton
            icon={editIcon}
            altText="Редактировать"
            onClick={() => setIsVideoEditable((prev) => !prev)} 
          />
        </div>
      </ActionIconsWrapper>

      <DragAndDropField
        files={newFiles}
        files_from_storage={storageFiles}
        onFileSelected={handleFileSelected}
        onRemoveFile={handleRemoveFile}
        placeholder="Перетащите файлы сюда или нажмите, чтобы выбрать."
        maxFiles={5}
      />

      {/* Домашнее задание */}
      <div>
        <AssignmentsContainer
        courseId={courseId}
        lessonId={lessonId}
        />
        <ButtonWrapper>
          <Button
            onClick={handleSave} // Сохраняем урок
            variant="active"
            width="160px"
            height="50px"
          >
            Сохранить
          </Button>
        </ButtonWrapper>
      </div>
    </LessonDetailsContainer>
  );
};

export default LessonDetails;


