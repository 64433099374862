import React from "react";
import { StyledButton } from "./Button.styles";
import { ButtonProps } from "./Button.types";

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = "button",
  variant = "active",
  disabled = false,
  width,
  height,
  isStaticOnHover = false, // Новое свойство по умолчанию false
}) => {
  return (
    <StyledButton
      onClick={onClick}
      type={type}
      variant={variant}
      disabled={disabled}
      width={width}
      height={height}
      isStaticOnHover={isStaticOnHover} // Передаем свойство
    >
      {children}
    </StyledButton>
  );
};

export default Button;
