import axiosInstance from "../axiosConfig";
import { APIResponse, StudentPurchase } from "./studentService.types";

// Получение списка учеников
export const fetchStudents = async (
  page: number = 1,
  pageSize: number = 7,
  ordering: string = ""
): Promise<APIResponse<StudentPurchase>> => {
  const url = `/courses/teacher/purchases/`;
  try {
    const response = await axiosInstance.get(url, {
      params: {
        page,
        page_size: pageSize,
        ordering,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка учеников:", error);
    throw error;
  }
};
