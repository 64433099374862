import React, { useState, useRef, useEffect } from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
  CloseButton,
  FieldWrapper,
  Input,
  ErrorMessage,
  ButtonWrapper,
} from "./DocumentModal.styles";
import { DocumentModalProps, Field } from "./DocumentModal.types";
import Button from "../../Buttons/Button";
import DropdownWithInput from "../../DropdownWithInput";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import { getTemplateSchemaValues, updateTemplateSchemaValue } from "../../../services/templateSchemaService/templateSchemaService";

const DocumentModal: React.FC<DocumentModalProps> = ({
  title,
  fields,
  onSubmit,
  submitButtonText = "Сохранить",
  onCancel,
  initialValues = {},
  IsSaved = true,
  templateId
}) => {
  const [formData, setFormData] = useState<Record<string, string>>(
    fields.reduce((acc, field) => {
      acc[field.name] = initialValues[field.name] || "";
      return acc;
    }, {} as Record<string, string>)
  );

  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [savedData, setSavedData] = useState<Record<string, string>>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState(false);

  useEffect(() => {
    if (IsSaved) {
      getTemplateSchemaValues(

        fields.map((field) => ({
          template_id: templateId,
          id: field.id,
        }))
      )
        .then((data) => {
          const savedValues = data.reduce((acc, item) => {
            acc[item.id] = item.value || "";
            return acc;
          }, {} as Record<string, string>);
          setSavedData(savedValues);
        })
        .catch(console.error);
    }
  }, [IsSaved, fields, templateId]);


  useEffect(() => {
    const allRequiredFieldsFilled = fields.every(
      (field) => !field.required || formData[field.name]?.trim() !== ""
    );
    setIsSubmitDisabled(!allRequiredFieldsFilled);

    const anyFieldFilled = Object.values(formData).some((value) => value.trim() !== "");
    setIsSaveDisabled(!anyFieldFilled);
  }, [formData, fields]);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleTextareaChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleSubmit = () => {
    const newErrors = fields.reduce<Record<string, string | null>>((acc, field) => {
      if (field.required && !formData[field.name]?.trim()) {
        acc[field.name] = "Это поле обязательно";
      }
      return acc;
    }, {});

    setErrors(newErrors);

    if (Object.values(newErrors).every((err) => !err)) {
      onSubmit(formData);
    }
  };

  const handleSave = async () => {
    try {
      const dataToSave = fields
        .filter((field) => formData[field.name]?.trim() !== "")
        .map((field) => ({
          templateId: templateId,
          schemaId: field.id,
          value: formData[field.name],
        }));

      if (dataToSave.length === 0) {
        alert("Нет данных для сохранения");
        return;
      }

      const result = await updateTemplateSchemaValue(dataToSave);

      if (result.errors?.length === 0) {
        setIsSavedSuccessfully(true);
        setTimeout(() => setIsSavedSuccessfully(false), 3000);
      } else {
        alert(`Ошибки при сохранении: ${result.errors?.join(", ")}`);
      }
    } catch (error) {
      console.error("Ошибка при сохранении данных:", error);
    }
  };




  const handleInsertSaved = () => {
    const mappedSavedData = fields.reduce((acc, field) => {
      if (savedData[field.id] !== undefined) {
        acc[field.name] = savedData[field.id];
      }
      return acc;
    }, {} as Record<string, string>);

    setFormData({
      ...formData,
      ...mappedSavedData,
    });
  };


  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>{title}</h2>
          <CloseButton onClick={onCancel}>
            <img src={closeIcon} alt="Закрыть" />
          </CloseButton>
        </ModalHeader>
        <Divider />
        <ModalBody>
          <form onSubmit={(e) => e.preventDefault()}>
            {fields.map((field) => (
              <FieldWrapper key={field.name}>
                {errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
                {field.type === "textarea" ? (
                  <Input
                    as="textarea"
                    ref={textareaRef}
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    onChange={(e) => handleTextareaChange(field.name, e.target.value)}
                    hasError={!!errors[field.name]}
                  />
                ) : field.type === "select" && field.options ? (
                  <DropdownWithInput
                    options={field.options}
                    placeholder={field.placeholder || "Выберите значение"}
                    value={formData[field.name]}
                    onChange={(value) => handleChange(field.name, value)}
                  />
                ) : (
                  <Input
                    type="text"
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    hasError={!!errors[field.name]}
                  />
                )}
              </FieldWrapper>
            ))}
          </form>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button
              type="button"
              disabled={isSubmitDisabled}
              width="100%"
              onClick={handleSubmit}
            >
              {submitButtonText}
            </Button>
            {IsSaved && (
              <>
                <Button
                  type="button"
                  variant="flatNotFilled"
                  disabled={isSaveDisabled || isSavedSuccessfully}
                  width="100%"
                  onClick={handleSave}
                >
                  {isSavedSuccessfully ? "Сохранено" : "Сохранить данные"}
                </Button>

                {Object.values(savedData).some((value) => value?.trim() !== "") && (
                  <Button
                    type="button"
                    width="100%"
                    onClick={handleInsertSaved}
                    variant="flatNotFilled"
                  >
                    Вставить сохраненное
                  </Button>
                )}
              </>
            )}
          </ButtonWrapper>
        </ModalFooter>

      </ModalContent>
    </ModalOverlay>
  );
};

export default DocumentModal;
