import React, { useState, useEffect, useRef } from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
  CloseButton,
  FieldWrapper,
  Input,
  ErrorMessage,
  DragAndDropLinkField,
} from "./CreateModal.styles";
import { CreateModalProps } from "./CreateModal.types";
import Button from "../../Buttons/Button";
import DragAndDropField from "../../DragAndDropField/DragAndDropField";
import closeIcon from "../../../assets/icons/closeIcon.svg";

import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import DropdownWithInput from "../../DropdownWithInput";
import ReactDOM from "react-dom";

const CreateModal: React.FC<CreateModalProps> = ({
  title,
  fields,
  onSubmit,
  submitButtonText = "Создать курс",
  hasDragAndDrop = false,
  onCancel,
  onRemoveFile,
  initialValues = {},
  files_from_storage = [],
  disableToggleSwitch = false,
  maxFiles = 5,
  externalErrors = {},
}) => {
  const [formData, setFormData] = useState<Record<string, string>>(
    fields.reduce((acc, field) => {
      acc[field.name] = initialValues[field.name] || field.value || "";
      return acc;
    }, {} as Record<string, string>)
  );

  const [fileData, setFileData] = useState<File[] | null>(
    Array.isArray(initialValues.files) ? (initialValues.files as File[]) : null
  );
  const [linkData, setLinkData] = useState<string>(initialValues.link || "");
  const [errors, setErrors] = useState<Record<string, string | null>>(externalErrors || {}); // Initialize with externalErrors
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [activeTab, setActiveTab] = useState<string>("Файл");

  useEffect(() => {
    const allRequiredFieldsFilled = fields.every(
      (field) =>
        !field.required ||
        (field.name !== "files" &&
          field.name !== "link" &&
          formData[field.name]?.trim() !== "") ||
        (activeTab === "Файл" && fileData && fileData.length > 0) ||
        (activeTab === "Ссылка" && linkData.trim() !== "")
    );
    setIsSubmitDisabled(!allRequiredFieldsFilled);
  }, [formData, fields, activeTab, fileData, linkData]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    setLinkData(initialValues.video_url || "");
  }, []);

  useEffect(() => {
    setErrors((prevErrors) => {
      if (JSON.stringify(prevErrors) !== JSON.stringify(externalErrors)) {
        return {
          ...prevErrors,
          ...externalErrors,
        };
      }
      return prevErrors;
    });
  }, [externalErrors]);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleTextareaChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }

    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleLinkChange = (value: string) => {
    setLinkData(value);
    if (errors["link"]) {
      setErrors({ ...errors, link: null });
    }
  };

  const handleToggleTab = (option: string) => {
    if (option === "Файл" && !fileData) {
      setFileData([]);
    }
    setActiveTab(option);
  };

  const handleSubmit = () => {
    const newErrors = fields.reduce<Record<string, string | null>>((acc, field) => {
      if (field.required && !formData[field.name]?.trim()) {
        acc[field.name] = "Это поле обязательно";
      }
      return acc;
    }, {});

    setErrors(newErrors);

    if (Object.values(newErrors).every((err) => !err)) {
      const dataToSubmit = {
        ...formData,
        files: activeTab === "Файл" ? fileData : null,
        video_url: activeTab === "Ссылка" ? linkData : null,
      };
      onSubmit(dataToSubmit);
    }
  };
  console.log(errors);
  console.log(fields);
  const modalContent = (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>{title}</h2>
          <CloseButton onClick={onCancel}>
            <img src={closeIcon} alt="Закрыть" />
          </CloseButton>
        </ModalHeader>
        <Divider />
        <ModalBody>
          <form onSubmit={(e) => e.preventDefault()}>
            {fields.map(
              (field) =>
                (!hasDragAndDrop || field.name !== "link") && (
                  <FieldWrapper key={field.name}>
                    {errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
                    {field.type === "textarea" ? (
                      <Input
                        id={field.name}
                        as="textarea"
                        ref={textareaRef}
                        placeholder={field.placeholder}
                        value={formData[field.name]}
                        onChange={(e) => handleTextareaChange(field.name, e.target.value)}
                        hasError={!!errors[field.name]}
                      />
                    ) : field.type === "selectWithInput" && field.options ? (
                      <DropdownWithInput
                        options={field.options}
                        placeholder={field.placeholder || "Выберите значение"}
                        value={formData[field.name]}
                        onChange={(value) => handleChange(field.name, value)}
                      />
                    ) : (
                      <Input
                        type={field.type}
                        placeholder={field.placeholder}
                        value={formData[field.name]}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                        hasError={!!errors[field.name]}
                      />
                    )}
                  </FieldWrapper>
                )
            )}

            {hasDragAndDrop && (
              <DragAndDropLinkField>
                {!disableToggleSwitch && (
                  <ToggleSwitch
                    options={["Ссылка", "Файл"]}
                    activeOption={activeTab}
                    onToggle={handleToggleTab}
                  />
                )}

                {(disableToggleSwitch ? activeTab === "Файл" : true) &&
                  activeTab === "Файл" && (
                    <DragAndDropField
                      files={fileData}
                      onFileSelected={setFileData}
                      placeholder={`Перетащите файлы сюда или нажмите, чтобы выбрать.`}
                      maxFiles={maxFiles}
                      files_from_storage={files_from_storage}
                      onRemoveFile={onRemoveFile}
                    />
                  )}

                {(disableToggleSwitch ? activeTab === "Ссылка" : true) &&
                  activeTab === "Ссылка" && (
                    <FieldWrapper className="link-field">
                      {errors["video_url"] && <ErrorMessage>{errors["video_url"]}</ErrorMessage>}
                      <Input
                        type="text"
                        placeholder="Введите ссылку"
                        value={linkData}
                        onChange={(e) => handleLinkChange(e.target.value)}
                        hasError={!!errors["link"]}
                      />
                    </FieldWrapper>
                  )}
              </DragAndDropLinkField>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            disabled={isSubmitDisabled}
            width="100%"
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default CreateModal;
