import { AssignmentResponseDetail } from "../../pages/AssignmentResponseDetailPage/AssignmentResponseDetailPage.types";
import axiosInstance from "../axiosConfig";
import { AssignmentResponseData, AssignmentReviewCreateData } from "./assignmentResponseService.types";

export const fetchAssignmentResponses = async (
    page: number = 1,
    pageSize: number = 10,
    ordering: string = ""
  ): Promise<AssignmentResponseData> => {
    const url = `/courses/assignment-responses/`;
    try {
      const response = await axiosInstance.get<AssignmentResponseData>(url, {
        params: { page, page_size: pageSize, ordering },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const fetchAssignmentResponseById = async (id: number): Promise<AssignmentResponseDetail> => {
    const url = `/courses/assignment-responses/${id}/`;
    try {
      const response = await axiosInstance.get<AssignmentResponseDetail>(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  

  export const createAssignmentReview = async (data: AssignmentReviewCreateData): Promise<void> => {
    const url = `/student/create-assignment-review/`; 
    try {
      await axiosInstance.post(url, data);
      console.log("Фидбек успешно отправлен");
    } catch (error) {
      console.error("Ошибка при отправке фидбека:", error);
      throw error;
    }
  };