import React from "react";
import {
  TariffCardContainer,
  TariffTitle,
  TariffInfoContainer,
  TariffPrice,
  TariffExpiration,
  TariffDescription,
  TariffDescriptionContainer,
} from "./TariffCard.styles";
import Button from "../Buttons/Button";
import { TariffCardProps } from "./TariffCard.types";
import { formatPrice } from "../../utils/formatNumber";

const TariffCard: React.FC<TariffCardProps> = ({
  title,
  description,
  price,
  isActive,
  expirationDate,
  onClick,
}) => {
  const buttonVariant = isActive ? "pink" : "default";

  return (
    <TariffCardContainer>
      <TariffTitle>{title}</TariffTitle>
      <TariffInfoContainer>
        <TariffPrice>{formatPrice(price)}</TariffPrice>
        <TariffExpiration>действует до {expirationDate}</TariffExpiration>
      </TariffInfoContainer>
      <TariffDescriptionContainer>
        {description.map((line, index) => (
          <TariffDescription key={index}>{line}</TariffDescription>
        ))}
      </TariffDescriptionContainer>
      <Button variant={buttonVariant} onClick={onClick}>
        {isActive ? "Управлять" : "Перейти"}
      </Button>
    </TariffCardContainer>
  );
};

export default TariffCard;
