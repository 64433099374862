import styled, { css } from "styled-components";

export const StyledBadge = styled.div.withConfig({
  shouldForwardProp: (prop) => !["variant", "asButton"].includes(prop),
})<{ variant: "green" | "red"; asButton: boolean }>`
  display: inline-block;
  min-width: 52px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 6px 12px 6px 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 5px;
  cursor: ${({ asButton }) => (asButton ? "pointer" : "default")};
  ${({ variant }) =>
    variant === "green"
      ? css`
          border: 1px solid rgb(86, 189, 116);
          box-shadow: 0px 4px 4px 0px rgba(86, 189, 116, 0.6);
          background: rgba(86, 189, 116, 0.1);
          color: rgb(86, 189, 116);
        `
      : css`
          border: 1px solid rgb(242, 40, 98);
          box-shadow: 0px 4px 4px 0px rgba(242, 40, 98, 0.6);
          background: rgba(242, 40, 98, 0.1);
          color: rgb(242, 40, 98);
        `}

  &:hover {
    ${({ asButton, variant }) =>
      asButton &&
      css`
        box-shadow: 0px 6px 6px 0px
          ${variant === "green"
            ? "rgba(86, 189, 116, 0.8)"
            : "rgba(242, 40, 98, 0.8)"};
      `}
  }
`;
