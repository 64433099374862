import React, { useState, useEffect, DragEvent, ChangeEvent } from "react";
import { Tooltip } from "primereact/tooltip";
import {
  DropZone,
  InfoText,
  FileList,
  FileItem,
  RemoveFileButton,
  FileIcon,
  FileName,
} from "./DragAndDropField.styles";
import fileIcon from "../../assets/icons/fileIcon.svg";
import { FileDisplay } from "../FileToDisplay/FileToDisplay.types";
import { DragAndDropFieldProps } from "./DragAndDropField.types";
import { ErrorMessage } from "../Modals/CreateModal/CreateModal.styles";

const DragAndDropField: React.FC<DragAndDropFieldProps> = ({
  accept = "",
  placeholder = "Нажмите или перетащите файл(ы) для загрузки. Максимум 10 файлов",
  infoText = "",
  files: externalFiles = [],
  files_from_storage = [],
  onFileSelected,
  onRemoveFile,
  showFileList = true,
  dropZoneBackground,
  maxFiles = 5,
  maxFileSize = 10 * 1024 * 1024,
}) => {
  const [files, setFiles] = useState<File[]>(externalFiles || []);
  const [storageFiles, setStorageFiles] = useState<FileDisplay[]>(
    files_from_storage || []
  );
  const [dragOver, setDragOver] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setFiles(externalFiles || []);
  }, [externalFiles]);

  useEffect(() => {
    setStorageFiles(files_from_storage || []);
  }, [files_from_storage]);

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    addFiles(droppedFiles);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    addFiles(selectedFiles);
  };

  const addFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter((file) => {
      if (file.size > maxFileSize) {
        setError(
          `Файл "${file.name}" превышает допустимый размер ${
            maxFileSize / (1024 * 1024)
          } MB`
        );
        return false;
      }
      return true;
    });
    const combinedFiles = [...files, ...validFiles];
    if (maxFiles && combinedFiles.length > maxFiles) {
      const allowedFiles = combinedFiles.slice(0, maxFiles);
      setFiles(allowedFiles);
      onFileSelected(allowedFiles);
    } else {
      const uniqueFiles = combinedFiles.filter(
        (file, index, self) =>
          self.findIndex((f) => f.name === file.name) === index
      );
      setFiles(uniqueFiles);
      onFileSelected(uniqueFiles);
    }
  };

  const removeFile = (fileToRemove: File | FileDisplay) => {
    if (fileToRemove instanceof File) {
      const updatedFiles = files.filter((file) => file !== fileToRemove);
      setFiles(updatedFiles);
      onFileSelected(updatedFiles);
    } else {
      const updatedStorageFiles = storageFiles.filter(
        (file) => file.id !== fileToRemove.id
      );
      setStorageFiles(updatedStorageFiles);

      if (onRemoveFile) {
        onRemoveFile(fileToRemove.id);
      }
    }
  };

  const downloadFile = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop() || "download";
    link.target = "_blank";
    link.click();
  };

  return (
    <div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <DropZone
        dragOver={dragOver}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        style={{ background: dropZoneBackground }}
      >
        <input type="file" accept={accept} multiple onChange={handleFileChange} />
        <p>{placeholder}</p>
      </DropZone>
      {infoText && <InfoText>{infoText}</InfoText>}
      {showFileList && (
        <FileList>
          {storageFiles.map((file, index) => (
            <FileItem key={`storage-${index}`} onClick={() => downloadFile(file.url)}>
              <FileIcon src={fileIcon} alt="Файл" />
              <FileName className={`file-link-${index}`}>{file.name}</FileName>
              <RemoveFileButton onClick={() => removeFile(file)}>×</RemoveFileButton>
              <Tooltip
                target={`.file-link-${index}`}
                content={file.name}
                mouseTrack
                mouseTrackLeft={10}
              />
            </FileItem>
          ))}
          {files.map((file, index) => (
            <FileItem key={`file-${index}`}>
              <FileIcon src={fileIcon} alt="Файл" />
              <FileName className={`file-link-${index}`}>{file.name}</FileName>
              <RemoveFileButton onClick={() => removeFile(file)}>×</RemoveFileButton>
              <Tooltip
                target={`.file-link-${index}`}
                content={file.name}
                mouseTrack
                mouseTrackLeft={10}
              />
            </FileItem>
          ))}
        </FileList>
      )}
    </div>
  );
};

export default DragAndDropField;
