import styled from "styled-components";

export const PaginatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 29px;
  margin-bottom: 25px;
  margin-top: 36px;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1;

  span {
    color: rgb(185, 185, 185);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const NavButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const PageButton = styled.button`
  border: none;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(185, 185, 185);
  background: none;
  transition: background 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ActivePageButton = styled(PageButton)`
  color: rgb(0, 0, 0);
  font-weight: 500;
  line-height: 24px;
  padding: 4px 8px 4px 8px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 8px;
  background: rgb(250, 250, 250);

  &:hover {
    transform: scale(1.05);
  }
`;

export const NavButton = styled.button<{ direction: "left" | "right" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 8px 16px 8px 16px;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 8px;
  background: rgb(250, 250, 250);
  ${({ direction }) =>
    direction === "left" &&
    `
      flex-direction: row-reverse;
    `}

  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: rgb(0, 0, 0);

  &:hover {
    background: #f0f0f0;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    transform: ${({ direction }) =>
      direction === "left" ? "rotate(135deg)" : "rotate(-45deg)"};
    transition: transform 0.2s;
  }
`;
