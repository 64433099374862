import styled from "styled-components";

export const ToggleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(247, 248, 248);
  border-radius: 10px;
  padding: 4px;
  width: 100%;
  border-radius: 15px;
  background: rgb(247, 247, 247);
  margin-bottom: 24px;
`;

export const ToggleButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 13px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  
  background: ${({ isActive }) =>
    isActive ? "rgb(114, 82, 220)" : "transparent"};
  color: ${({ isActive }) => (isActive ? "white" : "rgb(163, 167, 174);")};
  transition:
    background-color 0.2s,
    color 0.2s;

  &:hover {
    color: ${({ isActive }) =>
      isActive ? "white" : "rgb(114, 82, 220);"};
  }
`;
