import styled from "styled-components";

export const TableContainer = styled.div<{ isExpanded?: boolean; minHeight?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: none;
  border-radius: 16px;
  box-shadow:
    0px 9px 14.5px 0px rgba(0, 0, 0, 0.06),
    0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  height: ${({ isExpanded, minHeight }) => (isExpanded ? "100%" : `calc(100% - ${minHeight})`)};
  transition: height 0.3s ease;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.thead`
  th {
    text-align: left;
    color: rgb(31, 10, 53);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    padding-top: 25px; 
    padding-bottom: 10px;
    background-color: rgb(255, 255, 255);
    position: sticky;
    top: 0;
    z-index: 2;
  }

  span {
    white-space: nowrap;
  }
`;

export const TableRow = styled.tr<{ isExpanded?: boolean }>`
  position: ${({ isExpanded }) => (isExpanded ? "sticky" : "relative")};
  top: ${({ isExpanded }) => (isExpanded ? "100px" : "auto")};
  background-color: ${({ isExpanded }) => (isExpanded ? "white" : "transparent")};
  z-index: ${({ isExpanded }) => (isExpanded ? 2 : 1)};
  transition: top 0.3s ease;
  padding-top: 5px;
  padding-bottom: 5px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    z-index: 0;
    background-color: rgb(235, 235, 235);
  }
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td<{ isHidden?: boolean }>`
  padding-top: 10px; /* Верхний отступ */
  padding-bottom: 10px; /* Нижний отступ */
  font-size: 18px;
  font-weight: 400;
  padding-left: 10px;

  &:first-child {
    padding-left: 40px;
  }
  &:last-child {
    padding-right: 29px;
  }

  div.content {
    visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TableHeadCell = styled.div`
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-left: 10px;
  padding-bottom: 25px;

  th:first-child & {
    padding-left: 16px;
  }

  span {
    margin-left: 19px;
  }
`;

export const ExpandButton = styled.button<{ isExpanded: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ isExpanded }) => (isExpanded ? "rotate(180deg)" : "none")};
  transition: transform 0.3s ease;
`;

export const ExpandIcon = styled.img<{ isExpanded: boolean }>`
  width: 16px;
  height: 16px;
`;

export const PaginatorWrapper = styled.div`
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  padding-top: 10px; /* Верхний отступ */
  padding-bottom: 10px; /* Нижний отступ */
  z-index: 2;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px; /* Верхний отступ */
  padding-bottom: 10px; /* Нижний отступ */
`;
