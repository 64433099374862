import React, { useState } from "react";
import Button from "../../Buttons/Button";
import { LinkModalProps } from "./LinkModal.types";
import { CloseButton, Divider, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "./LinkModal.styles";
import closeIcon from "../../../assets/icons/closeIcon.svg";

const LinkModal: React.FC<LinkModalProps> = ({ isOpen, link, onCancel }) => {
  const [buttonText, setButtonText] = useState("Скопировать");

  const handleCopyClick = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link).then(() => {
        setButtonText("Скопировано");
        setTimeout(() => setButtonText("Скопировать"), 2000);
      }).catch((err) => {
        console.error("Ошибка копирования текста:", err);
      });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setButtonText("Скопировано");
      setTimeout(() => setButtonText("Скопировать"), 2000);
    }
  };
  

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Пригласи друга</h2>
          <CloseButton onClick={onCancel}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </ModalHeader>
        <Divider />
        <ModalBody>
          <p>Скопируй ссылку и отправь другу:</p>
          <div>
            <input
              type="text"
              value={link}
              readOnly
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #ddd",
                marginBottom: "10px",
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleCopyClick} variant="flatFilled" isStaticOnHover={true}>
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LinkModal;
