import axiosInstance from "../axiosConfig";

export const updateTemplateSchemaValue = async (
  values: { templateId: number; schemaId: number; value: string | number }[]
): Promise<{ success: boolean; errors?: string[] }> => {
  const url = `/documents/update-template-schema-value/`;

  const body = values.map(({ templateId, schemaId, value }) => ({
    template_id: templateId,
    id: schemaId,
    value,
  }));

  try {
    const response = await axiosInstance.post(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении значений схемы:", error);
    throw error;
  }
};

export const getTemplateSchemaValues = async (
  schemas: { template_id: number; id: number }[]
): Promise<{ template_id: number; id: number; value: string | null }[]> => {
  const url = `/documents/get-template-schema-values/`;

  try {
    const response = await axiosInstance.post(url, schemas, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении значений схем:", error);
    throw error;
  }
};
