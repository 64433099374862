import React from "react";
import { StatusBadgeProps } from "./StatusBadge.types";
import { StyledBadge } from "./StatusBadge.styles";

const StatusBadge: React.FC<StatusBadgeProps> = ({
  text,
  variant = "green",
  asButton = false,
  onClick,
}) => {
  return (
    <StyledBadge
      variant={variant}
      asButton={asButton}
      onClick={asButton ? onClick : undefined}
    >
      {text}
    </StyledBadge>
  );
};

export default StatusBadge;
