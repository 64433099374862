import axiosInstance from "../axiosConfig";
import { DocumentTemplate, GenerateDocumentRequest } from "./documentService.types";

// Получение списка шаблонов документов
export const fetchDocumentTemplates = async (): Promise<DocumentTemplate[]> => {
  const url = `/documents/templates/`;

  try {
    const response = await axiosInstance.get<DocumentTemplate[]>(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка документов:", error);
    throw error;
  }
};

// Генерация документа
export const generateDocument = async (data: GenerateDocumentRequest): Promise<Blob> => {
  const url = `/documents/generate-document/`;

  try {
    const response = await axiosInstance.post(url, data, {
      responseType: "blob",
    });

    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    return blob;
  } catch (error) {
    console.error("Ошибка при генерации документа:", error);
    throw error;
  }
};
