import styled from "styled-components";

export const LessonsPageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const ButtonWrapper = styled.div``;

export const FileLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BaseLinkItem = styled.div`
  display: flex;
  align-items: center;
  width: 122px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 8px;
  background: rgb(250, 250, 250);
  padding: 6px 12px 6px 12px;

  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 400;
`;
