import React, { useState, useEffect, useRef } from "react";
import {
  CoursesPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
} from "./CoursesPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import { Course, CourseResponse } from "../../services/courseService/courseService.types";
import {
  fetchCourses,
  softDeleteCourse,
  updateCourse,
  restoreCourse,
  createCourse,
  updateCourseById,
  fetchCategories,
} from "../../services/courseService/courseService";
import Button from "../../components/Buttons/Button";
import LessonsButton from "../../components/Buttons/LessonsButton";
import IconButton from "../../components/Buttons/IconButton";
import publishIcon from "../../assets/icons/publishIcon.svg";
import delIcon from "../../assets/icons/delIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import StatusBadge from "../../components/StatusBadge";
import DeletedRow from "../../components/DeletedRow";
import RestoredRow from "../../components/RestoredRow";
import { ConfirmModal, CreateModal } from "../../components/Modals";
import { CoursesPageProps } from "./CoursesPage.types";
import { Toast } from "primereact/toast";
import { formatPrice } from "../../utils/formatNumber";

const CoursesPage: React.FC<CoursesPageProps> = ({ viewMode }) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState<Course | null>(null);
  const [pendingDelete, setPendingDelete] = useState<number | null>(null);
  const [recentlyRestored, setRecentlyRestored] = useState<number | null>(null);
  const [recentlyCreated, setRecentlyCreated] = useState<number | null>(null);
  const [recentlyEdited, setRecentlyEdited] = useState<number | null>(null);
  const deleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [courseToEdit, setCourseToEdit] = useState<Course | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [externalErrors, setExternalErrors] = useState<Record<string, string>>({});
  const toast = useRef<Toast>(null);


  const openEditModal = (course: Course) => {
    setCourseToEdit(course);
    setIsCreateModalOpen(true);
  };

  const handleEditCourse = async (formData: Record<string, string | File[] | null>) => {
    if (!courseToEdit) return;
  
    setExternalErrors({});
    try {
      await updateCourseById(courseToEdit.id, {
        title: formData["courseName"] as string,
        category: formData["category"] as string,
        price: formData["price"] as string,
        description: formData["description"] as string,
      });
  
      setRecentlyEdited(courseToEdit.id);
  
      closeCreateModal();
      loadCourses();
  
      setTimeout(() => setRecentlyEdited(null), 3000);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};
  
        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }
  
        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка при обновлении курса:", error);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка обновления курса",
          life: 3000,
        });
      }
    }
  };
  

  const loadCourses = async () => {
    setLoading(true);
    setError(null);
    try {
      const response: CourseResponse = await fetchCourses(
        currentPage,
        pageSize,
        orderBy
      );
      setCourses(response.results);
      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (err) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки курсов", life: 3000 });
      console.error("Ошибка при загрузке курсов:", err);
      setError("Не удалось загрузить курсы.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categoryList = await fetchCategories();
        setCategories(categoryList);
      } catch (error) {
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки категорий", life: 3000 });
        console.error("Ошибка при загрузке категорий:", error);
      }
    };

    loadCategories();
  }, [currentPage]);

  useEffect(() => {
    loadCourses();
  }, [currentPage, pageSize, orderBy]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const openDeleteModal = (course: Course) => {
    setCourseToDelete(course);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (courseToDelete) {
      setPendingDelete(courseToDelete.id);
      setIsDeleteModalOpen(false);
      setCourseToDelete(null);

      deleteTimeoutRef.current = setTimeout(async () => {
        try {
          await softDeleteCourse(courseToDelete.id);
          setPendingDelete(null);
          loadCourses();
        } catch (error) {
          toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка мягкого удаления курса", life: 3000 });
          console.error("Ошибка при мягком удалении курса:", error);
        }
      }, 5000);
    }
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
    setCourseToDelete(null);
  };

  const handlePublish = async (courseId: number) => {
    try {
      await updateCourse(courseId, { is_approved_by_admin: true });
      loadCourses();
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка публикации курса", life: 3000 });
      console.error("Ошибка при публикации курса:", error);
    }
  };

  const restoreCourseHandler = async (courseId: number) => {
    if (deleteTimeoutRef.current) {
      clearTimeout(deleteTimeoutRef.current);
      deleteTimeoutRef.current = null;
    }

    try {
      await restoreCourse(courseId);
      setRecentlyRestored(courseId);
      setPendingDelete(null);

      loadCourses();

      setTimeout(() => setRecentlyRestored(null), 3000);
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка восстановления курса", life: 3000 });
      console.error("Ошибка при восстановлении курса:", error);
    }
  };

  const openCreateModal = () => {
    setCourseToEdit(null);
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateCourse = async (formData: Record<string, string | File[] | null>) => {
    setExternalErrors({});
    try {
      const userId = Number(
        document.cookie.split("; ").find((row) => row.startsWith("user_id="))?.split("=")[1]
      );

      const courseData = {
        title: formData.courseName as string,
        category: formData.category as string,
        price: formData.price as string,
        description: formData.description as string,
        instructor: userId,
      };

      const createdCourse = await createCourse(courseData);

      setRecentlyCreated(createdCourse.id);

      closeCreateModal();
      loadCourses();

      setTimeout(() => setRecentlyCreated(null), 3000);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};

        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }

        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка при создании курса:", error);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка создания курса",
          life: 3000,
        });
      }
    }
  };


  const headers = [
    { label: "Название", key: "title", fieldName: "title" },
    { label: "Категория", key: "category", fieldName: "category" },
    { label: "Цена", key: "price", fieldName: "price" },
    { label: "Выложено", key: "status", fieldName: "is_approved_by_admin" },
    {
      label: viewMode === "lessons" ? "Уроки" : "Домашние задания",
      key: "lessons",
      sortable: false
    },
    { label: "", key: "edit", sortable: false, width: "10px" },
    { label: "", key: "delete", sortable: false, width: "10px" },
    { label: "", key: "publish", sortable: false, width: "10px" },
  ];

  const rows = courses.map((course) => {
    if (course.id === pendingDelete) {
      return {
        title: (
          <DeletedRow
            id={course.id}
            onRestore={restoreCourseHandler}
            colSpan={headers.length}
            message={`Курс ${course.title} будет удален через 5 секунд.`}
            targetText="Восстановить курс"
          />
        ),
      };
    }

    return {
      title: course.title,
      category: course.category,
      price: `${formatPrice(course.price)} руб.`,
      status: (
        <StatusBadge
          text={course.is_approved_by_admin ? "ДА" : "НЕТ"}
          variant={course.is_approved_by_admin ? "green" : "red"}
        />
      ),
      lessons: course.with_lessons ? (
        <LessonsButton
          href={`/courses/${course.id}/${viewMode}`}
          text="Смотреть"
        />
      ) : (
        <LessonsButton
          href={`/courses/${course.id}/${viewMode}`}
          text="Отсутствуют"
          noLessons={true}
        />
      ),
      edit: (
        <IconButton
          icon={editIcon}
          altText="Редактировать"
          onClick={() => openEditModal(course)}
        />
      ),
      delete: (
        <IconButton
          icon={delIcon}
          altText="Удалить"
          onClick={() => openDeleteModal(course)}
        />
      ),
      publish: !course.is_approved_by_admin ? (
        <IconButton
          icon={publishIcon}
          altText="Опубликовать"
          tooltip="Опубликовать"
          onClick={() => handlePublish(course.id)}
        />
      ) : null,
    };
  });

  if (recentlyRestored !== null) {
    const restoredIndex = courses.findIndex((c) => c.id === recentlyRestored);
    if (restoredIndex !== -1) {
      rows.splice(restoredIndex + 1, 0, {
        title: (
          <RestoredRow
            colSpan={headers.length}
            message="Курс успешно восстановлен"
          />
        ),
      });
    }
  }

  if (recentlyCreated !== null) {
    const createdIndex = courses.findIndex((c) => c.id === recentlyCreated);
    if (createdIndex !== -1) {
      rows.splice(createdIndex + 1, 0, {
        title: (
          <RestoredRow
            colSpan={headers.length}
            message="Курс успешно создан"
          />
        ),
      });
    }
  }

  if (recentlyEdited !== null) {
    const editedIndex = courses.findIndex((c) => c.id === recentlyEdited);
    if (editedIndex !== -1) {
      rows.splice(editedIndex + 1, 0, {
        title: (
          <RestoredRow
            colSpan={headers.length}
            message="Курс успешно отредактирован"
          />
        ),
      });
    }
  }

  const rowAmountOptions = [
    { label: "7 курсов", value: 7 },
    { label: "15 курсов", value: 15 },
    { label: "20 курсов", value: 20 },
  ];
  return (
    <CoursesPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>
            {viewMode === "lessons" ? "Мои курсы" : "Домашние задания"}
          </Title>
          <ButtonWrapper>
            <Button
              onClick={openCreateModal}
              type="button"
              variant="active"
              width="160px"
              height="50px"
            >
              Новый курс
            </Button>
          </ButtonWrapper>
        </HeaderContainer>
        <Table
          headers={headers}
          rows={rows}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onSort={handleSort}
          rowAmountOptions={rowAmountOptions}
          specialRowKey="title"
          loading={loading}
        />
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          title="Подтверждение"
          message={`Вы уверены, что хотите удалить этот курс?`}
          onConfirm={confirmDelete}
          onCancel={closeModal}
        />
        {isCreateModalOpen && (
          <CreateModal
            title={courseToEdit ? "Редактировать курс" : "Новый курс"}
            fields={[
              { name: "courseName", type: "text", placeholder: "Название курса", required: true },
              { name: "price", type: "text", placeholder: "Стоимость", required: true },
              {
                name: "category",
                type: "selectWithInput",
                placeholder: "Категория",
                options: categories,
                required: true,
              },
              { name: "description", type: "textarea", placeholder: "Описание" },
            ]}
            onSubmit={courseToEdit ? handleEditCourse : handleCreateCourse}
            submitButtonText={courseToEdit ? "Сохранить" : "Создать"}
            onCancel={closeCreateModal}
            hasDragAndDrop={false}
            initialValues={
              courseToEdit
                ? {
                  courseName: courseToEdit.title,
                  price: courseToEdit.price.toString(),
                  category: courseToEdit.category,
                  description: courseToEdit.description,
                }
                : {}
            }
            externalErrors={externalErrors}
          />
        )}
      </ContentWrapper>
    </CoursesPageContainer>
  );
};

export default CoursesPage;
