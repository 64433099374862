import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalWrapper,
  FormWrapper,
  FieldWrapper,
  Input,
  ErrorMessage,
  Backdrop,
  FooterLinksWrapper,
  FooterLinkItem,
  PasswordToggle,
  PasswordIcon,
} from "./FormModal.styles";
import { FormModalProps } from "./FormModal.types";
import Button from "../../Buttons/Button";

import eyeIcon from "../../../assets/icons/eyeIcon.svg";
import eyeClosed from "../../../assets/icons/eyeClosed.svg";

const FormModal: React.FC<FormModalProps> = ({
  title,
  fields,
  onSubmit,
  hasBackdrop = true,
  footerLinks = [],
  submitButtonText = "Submit",
  externalErrors = {},
  isLoading = false,
}) => {
  const [formData, setFormData] = useState<Record<string, string>>(
    fields.reduce<Record<string, string>>((acc, field) => {
      acc[field.name] = field.value || "";
      return acc;
    }, {})
  );
  const [errors, setErrors] = useState<Record<string, string | null>>(externalErrors);
  const [passwordVisibility, setPasswordVisibility] = useState<Record<string, boolean>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const { t } = useTranslation();


  const checkRequiredFields = () => {
    const allRequiredFilled = fields.every(
      (field) =>
        !field.required || // Игнорируем необязательные поля
        (formData[field.name] && formData[field.name].trim() !== "") // Проверяем значение
    );
    setIsSubmitDisabled(!allRequiredFilled);
  };

  useEffect(() => {
    setErrors((prevErrors) => {
      if (JSON.stringify(prevErrors) !== JSON.stringify(externalErrors)) {
        return {
          ...prevErrors,
          ...externalErrors,
        };
      }
      return prevErrors;
    });
  }, [externalErrors]);
  

  useEffect(() => {
    checkRequiredFields();
  }, [formData, fields]);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }

    checkRequiredFields(); 
  };

  const handleSubmit = () => {
    const newErrors: Record<string, string | null> = {};
    fields.forEach((field) => {
      let error: string | null = null;

      if (field.rules) {
        for (const rule of field.rules) {
          error = rule(formData[field.name]);
          if (error) break;
        }
      }

      if (!error && field.validate) {
        error = field.validate(formData[field.name]);
      }

      newErrors[field.name] = error;
    });

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === null)) {
      onSubmit(formData);
    }
  };

  const togglePasswordVisibility = (fieldName: string) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  return (
    <>
      {hasBackdrop && <Backdrop />}
      <ModalWrapper>
        <FormWrapper>
          <h2>{title}</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            {fields.map((field) => (
              <FieldWrapper key={field.name}>
                {errors[field.name] && (
                  <ErrorMessage>{errors[field.name]}</ErrorMessage>
                )}
                <div style={{ position: "relative" }}>
                  <Input
                    id={field.name}
                    type={
                      field.type === "password" && passwordVisibility[field.name]
                        ? "text"
                        : field.type
                    }
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    hasError={!!errors[field.name]}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    width={field.width}
                    height={field.height}
                  />
                  {field.type === "password" && (
                    <PasswordToggle onClick={() => togglePasswordVisibility(field.name)}>
                      <PasswordIcon
                        src={passwordVisibility[field.name] ? eyeClosed : eyeIcon}
                        alt={`${passwordVisibility[field.name]
                            ? t("auth.passwordShow")
                            : t("auth.passwordHide")
                          }`}
                      />
                    </PasswordToggle>
                  )}
                </div>
              </FieldWrapper>
            ))}
            <Button
              type="submit"
              variant={isSubmitDisabled || isLoading ? "disabled" : "active"}
              disabled={isSubmitDisabled || isLoading} // Кнопка неактивна при загрузке
              onClick={handleSubmit}
            >
              {isLoading ? t("Ожидание") : submitButtonText} {/* Текст кнопки меняется */}
            </Button>
          </form>
          {footerLinks.length > 0 && (
            <FooterLinksWrapper>
              {footerLinks.map((link, index) => (
                <FooterLinkItem
                  key={index}
                  href={link.href}
                  variant={link.variant}
                >
                  {link.text}
                </FooterLinkItem>
              ))}
            </FooterLinksWrapper>
          )}
        </FormWrapper>
      </ModalWrapper>
    </>
  );
};

export default FormModal;
