import styled from "styled-components";

export const ButtonContainer = styled.div`
  position: relative; /* Для позиционирования тултипа */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: rgba(250, 250, 250, 0);

  &:hover {
    transform: scale(1.05);
  }

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

export const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  text-align: center;
  padding: 4px 8px;
  font-size: 12px;
  position: absolute;
  bottom: 100%; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: opacity 0.3s ease;

  }
`;
