import styled from "styled-components";

export const AssignmentsWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid rgb(114, 82, 220);
  border-radius: 12px;
  padding: 12px 32px;
  background: rgba(114, 82, 220, 0.05);
`;

export const AssignmentsTitle = styled.h4`
  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 500;
  padding-top: 20px;
  margin: 0;
`;

export const AssignmentsDescription = styled.p`
  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 400;
  padding-top: 20px;
`;

export const AssignmentsLink = styled.a`
  display: block;
  color: rgb(114, 82, 220);
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding-top: 20px;
`;

export const FilesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 20px;
`;

export const AssignmentsFiles = styled.div`
  a {
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 22px;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: flex-end
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 16px;
`;

export const AssignmentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

