import React, { useMemo, useRef, useState } from "react";
import { FormModal, SuccessModal } from "../../components/Modals";
import { Field } from "../../components/Modals/FormModal/FormModal.types";
import { resetPassword, sendPasswordResetCode, verifyPasswordResetCode } from "../../services/authService/authService";
import { Toast } from "primereact/toast";

const ResetPasswordPage: React.FC = () => {
  const [step, setStep] = useState<"email" | "code" | "newPassword">("email");
  const [email, setEmail] = useState<string>(""); 
  const [externalErrors, setExternalErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const memoizedExternalErrors = useMemo(() => externalErrors, [externalErrors]);
  const toast = useRef<Toast>(null);

  const footerLinks = [
    { text: "Войти в аккаунт", href: "/login", variant: "primary" },
    { text: "Не помню почту", href: "/forgot-email", variant: "secondary" },
  ];

  const fieldsByStep: Record<"email" | "code" | "newPassword", Field[]> = {
    email: [
      {
        name: "email",
        type: "email",
        placeholder: "Электронная почта",
        required: true,
        width: "369px",
        validate: (value: string) =>
          !/\S+@\S+\.\S+/.test(value) ? "Неправильный формат почты" : null,
      },
    ],
    code: [
      {
        name: "resetCode",
        type: "text",
        placeholder: "Введите код",
        required: true,
        width: "369px",
      },
    ],
    newPassword: [
      {
        name: "password",
        type: "password",
        placeholder: "Пароль",
        required: true,
        width: "369px",
        validate: (value: string) =>
          value.length < 6
            ? "Пароль должен содержать не менее 6 символов"
            : null,
      },
      {
        name: "confirmPassword",
        type: "password",
        placeholder: "Повторите пароль",
        required: true,
        width: "369px",
        validate: (value: string) =>
          value.length < 6
            ? "Пароль должен содержать не менее 6 символов"
            : null,
      },
    ],
  };

  const handleSubmit = async (formData: Record<string, string>) => {
    setIsLoading(true);
    try {
      if (step === "email") {
        await sendPasswordResetCode(formData.email, "reset");
        setEmail(formData.email);
        setStep("code");
      } else if (step === "code") {
        await verifyPasswordResetCode(formData.resetCode, email);
        setStep("newPassword");
      } else if (step === "newPassword") {
        await resetPassword({ email, password: formData.password, confirmPassword: formData.confirmPassword });
        setShowSuccessModal(true);
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const formattedErrors: Record<string, string> = {};
  
        for (const key in serverErrors) {
          if (serverErrors.hasOwnProperty(key)) {
            formattedErrors[key] = serverErrors[key];
          }
        }
  
        setExternalErrors(formattedErrors);
      } else {
        console.error("Ошибка:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Произошла ошибка", life: 3000 });
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <>
    <Toast ref={toast} />
      <FormModal
        title={
          step === "email"
            ? "Восстановление пароля"
            : step === "code"
            ? "Введите код"
            : "Новый пароль"
        }
        fields={fieldsByStep[step]}
        onSubmit={handleSubmit}
        footerLinks={footerLinks}
        submitButtonText={
          step === "email"
            ? "Отправить код"
            : step === "code"
            ? "Подтвердить"
            : "Подтвердить"
        }
        externalErrors={memoizedExternalErrors}
        isLoading={isLoading}
      />
      {showSuccessModal && (
        <SuccessModal
          message="Ваш пароль успешно восстановлен!"
          onClose={() => {
            setShowSuccessModal(false);
            window.location.href = "/login";
          }}
        />
      )}
    </>
  );
};

export default ResetPasswordPage;