import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  ChartContainer,
  ControlsWrapper,
  ControlLabel,
  ModeSwitchContainer,
  ModeButton,
} from "./LineChart.styles";
import { ChartDataPoint, LineChartProps } from "./LineChart.types";
import CustomTooltip from "../CustomTooltip";
import { Calendar } from "primereact/calendar";
import { locale } from "primereact/api";

locale("ru");

const LineChart: React.FC<Omit<LineChartProps, "mode">> = ({ data, onDateChange, type }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [centeredIndex, setCenteredIndex] = useState<number | null>(null);
  const [filteredData, setFilteredData] = useState<ChartDataPoint[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [viewDate, setViewDate] = useState<Date | null>(null);
  const [mode, setMode] = useState<"date" | "month">("month"); // Управление режимом

  const getFirstDateForMode = () => {
    const currentDate = new Date();
    if (mode === "month") {
      currentDate.setDate(1);
    }
    return currentDate;
  };

  const updateChartData = (date: Date) => {
    if (mode === "date") {
      const filtered = filterDataForDayMode(date);
      setFilteredData(filtered);

      const index = filtered.findIndex((point) => {
        const pointDate = new Date(point.date);
        return pointDate.toDateString() === date.toDateString();
      });
      setCenteredIndex(index);
    } else {
      const filtered = filterDataForMonthMode(date);
      setFilteredData(filtered);

      const centerDateMonthKey = `${date.getFullYear()}-${date.getMonth()}`;
      const index = filtered.findIndex((point) => {
        const pointDate = new Date(point.date);
        const monthKey = `${pointDate.getFullYear()}-${pointDate.getMonth()}`;
        return monthKey === centerDateMonthKey;
      });
      setCenteredIndex(index);
    }
  };

  const filterDataForDayMode = (centerDate: Date) => {
    const startDate = new Date(centerDate);
    startDate.setDate(centerDate.getDate() - 15);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(centerDate);
    endDate.setDate(centerDate.getDate() + 15);
    endDate.setHours(0, 0, 0, 0);

    const dates: Date[] = [];
    const date = new Date(startDate);
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 5);
    }

    if (!dates.some((d) => d.toDateString() === centerDate.toDateString())) {
      dates.push(new Date(centerDate));
      dates.sort((a, b) => a.getTime() - b.getTime());
    }

    const chartDataPoints: ChartDataPoint[] = dates.map((d) => {
      const dataPoint = data.find((point) => {
        const pointDate = new Date(point.date);
        return pointDate.toDateString() === d.toDateString();
      });

      return {
        date: d.toISOString(),
        value: dataPoint ? dataPoint.value : 0,
      };
    });

    return chartDataPoints;
  };

  const filterDataForMonthMode = (centerDate: Date) => {
    const startDate = new Date(centerDate);
    startDate.setMonth(centerDate.getMonth() - 5);
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(centerDate);
    endDate.setMonth(centerDate.getMonth() + 5);
    endDate.setDate(1);
    endDate.setHours(0, 0, 0, 0);

    const months: Date[] = [];
    const date = new Date(startDate);
    while (date <= endDate && months.length < 10) {
      months.push(new Date(date));
      date.setMonth(date.getMonth() + 1);
    }

    const centerDateMonth = new Date(centerDate);
    centerDateMonth.setDate(1);
    centerDateMonth.setHours(0, 0, 0, 0);
    if (!months.some((d) => d.getTime() === centerDateMonth.getTime())) {
      months.push(centerDateMonth);
      months.sort((a, b) => a.getTime() - b.getTime());
      if (months.length > 10) {
        months.splice(0, months.length - 10);
      }
    }

    const monthDataMap = new Map<string, number>();
    data.forEach((point) => {
      const pointDate = new Date(point.date);
      const monthKey = `${pointDate.getFullYear()}-${pointDate.getMonth()}`;
      if (monthDataMap.has(monthKey)) {
        monthDataMap.set(monthKey, monthDataMap.get(monthKey)! + point.value);
      } else {
        monthDataMap.set(monthKey, point.value);
      }
    });

    const chartDataPoints: ChartDataPoint[] = months.map((d) => {
      const monthKey = `${d.getFullYear()}-${d.getMonth()}`;
      const aggregatedValue = monthDataMap.get(monthKey) || 0;
      return {
        date: d.toISOString(),
        value: aggregatedValue,
      };
    });

    return chartDataPoints;
  };

  useEffect(() => {
    if (!initialized) {
      const firstDate = getFirstDateForMode();
      setSelectedDate(firstDate);

      updateChartData(firstDate);

      onDateChange?.(firstDate);
      setInitialized(true);
    }
  }, [data, onDateChange, initialized, mode]);

  useEffect(() => {
    if (selectedDate) {
      updateChartData(selectedDate);
    }
  }, [selectedDate, data, mode]);

  const handleDateChange = (e: any) => {
    const selected = e.value as Date | null;
    setSelectedDate(selected);

    if (selected) {
      onDateChange?.(selected);
    }
  };

  const calculateXAxisOffset = () => {
    if (centeredIndex === null) return 0;

    const centerIndex = Math.floor(filteredData.length / 2);
    const offset = centeredIndex - centerIndex;
    return offset * 40;
  };

  const formatXAxisLabel = (date: string) => {
    const parsedDate = new Date(date);
    if (mode === "month") {
      const monthNames = [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июн",
        "Июл",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек",
      ];
      return `${monthNames[parsedDate.getMonth()]} ${parsedDate.getFullYear()}`;
    } else if (mode === "date") {
      return parsedDate.toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
      });
    }
    return "";
  };

  const getCalendarFormat = () => (mode === "month" ? "MM/yy" : "dd.mm.yy");

  return (
    <ChartContainer>
      
      <ControlsWrapper>
      <ModeSwitchContainer>
        <ModeButton isActive={mode === "date"} onClick={() => setMode("date")}>
          День
        </ModeButton>
        <ModeButton isActive={mode === "month"} onClick={() => setMode("month")}>
          Месяц
        </ModeButton>
      </ModeSwitchContainer>
        <ControlLabel>
          <Calendar
            key={mode}
            value={selectedDate}
            onChange={handleDateChange}
            dateFormat={getCalendarFormat()}
            placeholder="Выбрать дату отслеживания"
            view={mode === "month" ? "month" : "date"}
            showIcon
            className="custom-calendar"
            panelClassName="custom-calendar-panel"
          />
        </ControlLabel>
      </ControlsWrapper>

      <ResponsiveContainer width="97%" height={405}>
        <AreaChart data={filteredData}>
          <XAxis
            dataKey="date"
            tickFormatter={formatXAxisLabel}
            tick={{ fontSize: 12, fill: "#888" }}
            domain={["auto", "auto"]}
            padding={{ left: 20, right: 20 }}
            offset={calculateXAxisOffset()}
          />
          <YAxis
            tickFormatter={(value) => `${value}`}
            tick={{ fontSize: 12, fill: "#888" }}
          />
          <Tooltip content={<CustomTooltip  type={type}/>} />
          <Area
            type="linear"
            dataKey="value"
            stroke="#8884d8"
            strokeWidth={2}
            fill="url(#colorUv)"
            dot={{ r: 4 }}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="1">
              <stop
                offset="31.901%"
                stopColor="rgb(114, 82, 220)"
                stopOpacity={1}
              />
              <stop
                offset="154.902%"
                stopColor="rgb(114, 82, 220)"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default LineChart;
