import React, { useState, useRef, useEffect } from "react";
import {
    DropdownContainer,
    DropdownHeader,
    DropdownList,
    DropdownListItem,
} from "./Dropdown.styles";
import { DropdownProps } from "./Dropdown.types";

const Dropdown: React.FC<DropdownProps> = ({
    options,
    selectedValue,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openUpward, setOpenUpward] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (value: number) => {
        onChange(value);
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const enoughSpaceBelow = dropdownRect.bottom + 150 < windowHeight; // 150 - условная высота списка
            setOpenUpward(!enoughSpaceBelow);
        }
    }, [isOpen]);

    return (
        <DropdownContainer ref={dropdownRef}>
            <DropdownHeader isOpen={isOpen} onClick={handleToggle}>
                {options.find((option) => option.value === selectedValue)?.label}
            </DropdownHeader>
            {isOpen && (
                <DropdownList openUpward={openUpward}>
                    {options.map((option) => {
                        console.log("Option label:", option.label); 
                        console.log("Option value:", option.value);
                        return (
                            <DropdownListItem
                                key={option.value}
                                onClick={() => handleSelect(option.value)}
                            >
                               {option.label || "Нет текста"}
                            </DropdownListItem>
                        );
                    })}
                </DropdownList>
            )}
        </DropdownContainer>
    );
};

export default Dropdown;
