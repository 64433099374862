import React from "react";
import { IconButtonProps } from "./IconButton.types";
import { ButtonContainer, ButtonIcon, Tooltip } from "./IconButton.styles";

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  href,
  altText,
  tooltip,
}) => {
  if (href) {
    return (
      <ButtonContainer as="a" href={href}>
        <ButtonIcon src={icon} alt={altText} />
        {tooltip && <Tooltip className="tooltip">{tooltip}</Tooltip>}
      </ButtonContainer>
    );
  }

  return (
    <ButtonContainer as="button" onClick={onClick}>
      <ButtonIcon src={icon} alt={altText} />
      {tooltip && <Tooltip className="tooltip">{tooltip}</Tooltip>}
    </ButtonContainer>
  );
};

export default IconButton;
