import React, { useState, useEffect, useRef } from "react";
import {
  ReferralPageContainer,
  StatsWrapper,
  StatBlock,
  ChartWrapper,
  HeaderContainer,
  Title,
  ContentWrapper,
  FriendInviteConteiner,
  FriendInviteTitle,
  FriendInviteInfo,
  InfoContentWrapper,
  BalanceContainer,
  BalanceTitle,
  BalanceInfo,
  WithdrawMoneyButton,
  BalanceWrapper,
  FriendInviteWrapper,
  Button,
  CopyLinkWrapper,
} from "./ReferralPage.styles";
import Sidebar from "../../components/Sidebar";
import LineChart from "../../components/LineChart";
import copyLinkIcon from "../../assets/icons/copyLinkIcon.svg";

import { ChartDataPoint } from "./ReferralPage.types";
import { createBalanceOrder, fetchReferralData, fetchReferralLink } from "../../services/referralService/referralService";
import { ReferralResponse, ReferralData } from "../../services/referralService/referralService.types";
import WithdrawModal from "../../components/WithdrawModal/WithdrawModal";
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import Cookies from "js-cookie";
import { formatNumber } from "../../utils/formatNumber";
import { LinkModal } from "../../components/Modals";
import { Toast } from "primereact/toast";

const ReferralPage: React.FC = () => {
  const [filteredData, setFilteredData] = useState<ChartDataPoint[]>([]);
  const [referralData, setReferralData] = useState<ReferralResponse | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const loadReferralData = async () => {
      try {
        const data = await fetchReferralData();
        setReferralData(data);
        setFilteredData(mapReferralDataToChartData(data?.referral_data || []));
      } catch (error) {
        console.error("Ошибка при загрузке данных по рефералам:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки данных по рефералам", life: 3000 });
      }
    };

    loadReferralData();
  }, []);

  const mapReferralDataToChartData = (referralData: ReferralData[]): ChartDataPoint[] => {
    return referralData.map((data) => ({
      date: data.date,
      value: data.referral_count,
    }));
  };

  const handleWithdrawMoney = async (data: { amount: string; cardNumber: string }) => {
    try {
      await createBalanceOrder(
        
        {
          requisites: data.cardNumber,
          balance_sum: parseFloat(data.amount),
        }
      );
      setIsWithdrawModalOpen(false);
      setIsSuccessModalOpen(true); // Открыть модальное окно об успехе
    } catch (error) {
      console.error("Ошибка при создании заявки на вывод средств:", error);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка создания заявки на вывод средств", life: 3000 });
    }
  };

  const handleWithdrawClick = () => {
    setIsWithdrawModalOpen(true);
  };

  const handleWithdrawModalClose = () => {
    setIsWithdrawModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  const handleCopyLinkClick = async () => {
    const userId = Cookies.get("user_id");
    if (!userId) {
      console.error("Пользователь не найден. Проверьте куки.");
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Пользователь не найден. Проверьте куки.", life: 3000 });
      return;
    }

    const userIdNumber = parseInt(userId, 10);

    if (isNaN(userIdNumber)) {
      console.error("Неверный формат userId. Проверьте куки.");
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Неверный формат userId. Проверьте куки.", life: 3000 });
      
      return;
    }

    try {
      const link = await fetchReferralLink( userIdNumber); // Передаем преобразованный userId
      setReferralLink(link);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Ошибка при получении реферальной ссылки:", error);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка получения реферальной ссылки", life: 3000 });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDateChange = (selectedDate?: Date | null) => {
    if (!selectedDate) {
      setFilteredData(mapReferralDataToChartData(referralData?.referral_data || []));
      return;
    }

    const selectedMonth = selectedDate.getMonth();
    const selectedYear = selectedDate.getFullYear();

    const filtered = (referralData?.referral_data || []).filter((dataPoint: ReferralData) => {
      const date = new Date(dataPoint.date);
      const dataMonth = date.getMonth();
      const dataYear = date.getFullYear();

      const monthDifference = (dataYear - selectedYear) * 12 + (dataMonth - selectedMonth);

      return monthDifference >= -3 && monthDifference <= 3;
    });

    setFilteredData(mapReferralDataToChartData(filtered));
  };

  return (
    <ReferralPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Реферальная программа</Title>
          <InfoContentWrapper>
            <FriendInviteConteiner>
              <FriendInviteWrapper>
                <FriendInviteTitle>Пригласи друга сейчас!</FriendInviteTitle>
                <FriendInviteInfo>
                  Вы пригласили: {formatNumber(referralData?.number_of_referrals || 0)}
                </FriendInviteInfo>
              </FriendInviteWrapper>
              <CopyLinkWrapper>
                <Button onClick={handleCopyLinkClick}>
                  <img src={copyLinkIcon} alt="copyLinkIcon" />
                  <span>Скопировать ссылку</span>
                </Button>
              </CopyLinkWrapper>
            </FriendInviteConteiner>

            <BalanceContainer>
              <BalanceWrapper>
                <BalanceTitle>Баланс:</BalanceTitle>
                <BalanceInfo>{formatNumber(referralData?.balance || 0)} руб.</BalanceInfo>
              </BalanceWrapper>
              <WithdrawMoneyButton onClick={handleWithdrawClick}>
                Вывод средств
              </WithdrawMoneyButton>
            </BalanceContainer>

            <StatsWrapper>
              <StatBlock>
                Всего рефералов: <span>{formatNumber(referralData?.number_of_referrals || 0)}</span>
              </StatBlock>
              <StatBlock>
                Кол-во переходов: <span>{formatNumber(referralData?.number_of_clicks || 0)}</span>
              </StatBlock>
              <StatBlock>
                Заработано денег: <span>{formatNumber(referralData?.total_earned || 0)} руб.</span>
              </StatBlock>
            </StatsWrapper>
          </InfoContentWrapper>
        </HeaderContainer>
        <ChartWrapper>
          <LineChart data={filteredData} onDateChange={handleDateChange} type="реф" />
        </ChartWrapper>
      </ContentWrapper>

      <LinkModal isOpen={isModalOpen} link={referralLink} onCancel={handleModalClose} />

      {isWithdrawModalOpen && (
        <WithdrawModal
          availableAmount={referralData?.balance || 0}
          onSubmit={handleWithdrawMoney}
          onCancel={handleWithdrawModalClose}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessModal
          message="Заявка на вывод средств успешно создана!"
          onClose={handleSuccessModalClose}
        />
      )}
    </ReferralPageContainer>
  );
};

export default ReferralPage;
