import React from "react";
import { TooltipContainer, TooltipDate, TooltipRow } from "./CustomTooltip.styles";
import { CustomTooltipProps } from "./CustomTooltip.types";

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    const formattedValue = new Intl.NumberFormat("ru-RU").format(payload[0].value);

    return (
      <TooltipContainer>
        <TooltipDate>{`${label}`}</TooltipDate>
        <TooltipRow className="bold">{`+${formattedValue} ${type}`}</TooltipRow>
      </TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
