import styled from "styled-components";

export const SettingsFormContainer = styled.div`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 300px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px;

  h2 {
    color: rgb(31, 10, 53);
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }
`;

export const ModalBody = styled.div`
  color: rgb(185, 185, 185);
  font-size: 18px;
  font-weight: 500;
  margin: 30px 30px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(225, 224, 229);
`;

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 8px;
  background: #f7f8f8;
  &:focus {
    border-color: #724edc;
    background: rgba(114, 82, 220, 0.15);
  }
`;

export const SubmitButton = styled.button`
  padding: 14px 28px 14px 28px;
  width: 369px;
  border: none;
  border-radius: 12px;
  background: rgb(114, 82, 220);
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin: 0px 30px 30px 30px;
  &:hover {
    background: #603cbc;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ff4d4d;
  margin-bottom: 8px;
`;
