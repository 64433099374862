import React from "react";
import { FileDisplayContainer, FileIcon, FileName, RemoveFileButton } from "./FileToDisplay.styles";
import { FilesDisplayProps } from "./FileToDisplay.types";
import fileIcon from "../../assets/icons/fileIcon.svg";
import { Tooltip } from "primereact/tooltip";

const FileToDisplay: React.FC<FilesDisplayProps> = ({ files_from_storage, onRemoveFile }) => {
  return (
    <>
      {files_from_storage.map((file, index) => (
        <React.Fragment key={file.id}>
         
          <Tooltip
            target={`.file-link-${index}`}
            content={file.name}
            mouseTrack
            mouseTrackLeft={10}
          />

          <FileDisplayContainer>
            <a
              className={`file-link-${index}`}
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileIcon src={fileIcon} alt="Файл" />
              <FileName>{file.name}</FileName>
            </a>
            {onRemoveFile && (
              <RemoveFileButton onClick={() => onRemoveFile(file.id)}>×</RemoveFileButton>
            )}
          </FileDisplayContainer>
        </React.Fragment>
      ))}
    </>
  );
};

export default FileToDisplay;
