import styled from "styled-components";

export const DeletedRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 25px;

  box-shadow: inset 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  background: rgb(242, 40, 98);
  color: rgb(255, 255, 255);

  font-size: 16px;
  font-weight: 500;
`;

export const DeletedRowMessage = styled.span`
  flex-grow: 1; /* Растягивается, занимая доступное пространство */
  font-size: 18px;
  font-weight: 600;
  padding-left: 30px;
`;

export const RestoreButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;
