import { Answer } from './../SupportResponsePage/SupportResponsePage.styles';
import styled from "styled-components";

export const AssignmentResponsePageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 32px;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06), 0px 0px 6px rgba(31, 10, 53, 0.05);

  display: flex;
  flex-direction: column;

  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  margin-bottom: 24px;
  h1 {
    font-size: 32px;
    font-weight: 700;
    color: #1f0a35;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Section = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
border-radius: 16px;
padding: 16px;
box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06),0px 0px 0px 6px rgba(31, 10, 53, 0.05);
background: rgb(255, 255, 255);
`;

export const SectionTitle = styled.div`
color: rgba(31, 10, 53, 0.5);
font-size: 16px;
font-weight: 400;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ResponseText = styled.span`
  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 400;

`;

export const CommentInput = styled.textarea`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  background: none;
`;

export const RatingStars = styled.div`
  font-size: 24px;
  color: #ffc107;
  span {
    cursor: pointer;
  }
`;



export const LinkContainer = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
border-radius: 16px;
padding: 12px;
text-decoration: none;
background: rgb(235, 235, 235);
  max-width: fit-content;
  box-sizing: border-box;
`;

export const LinkItem = styled.a`
  align-items: center;
  gap: 8px;
  color: #1f0a35;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
`;

export const LinkIcon = styled.img`
  width: 32px;
  height: 32px;
`;


export const Footer = styled.div`
display: flex;
justify-content: flex-start;
margin-top: 32px;
`;

export const SaveButton = styled.button`
border-radius: 12px;
background: rgb(114, 82, 220);;
padding: 14px 28px 14px 28px;
color: rgb(255, 255, 255);
font-size: 18px;
font-weight: 600;
width: 238px;
cursor: pointer;

  &:hover {
    
  }
`;