import axiosInstance from "../axiosConfig";
import { StatisticResponse } from "./statisticService.types";

export const fetchStatistics = async (): Promise<StatisticResponse[]> => {
  const url = `/courses/statistics/`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении статистики курсов:", error);
    throw error;
  }
};
