import styled from "styled-components";

export const SupportResponsePageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: start;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const ResponseTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
  margin: 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 6px;
  padding: 16px 38px;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);

  h5 {
    margin: 0;
    color: rgb(31, 10, 53);
    font-size: 16px;
    font-weight: 400;
  }
`;

export const Answer = styled.div`
  color: rgb(31, 10, 53);
  font-size: 24px;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 0;
`;

export const ResponseContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 6px;
  padding: 16px 38px;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);

  h5 {
    margin: 0;
    color: rgb(31, 10, 53);
    font-size: 16px;
    font-weight: 400;
  }
`;
