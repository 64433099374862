import React, { useState, useEffect, useRef } from "react";
import {
    ContentWrapper,
    HeaderContainer,
    Title,
    ProfilePageContainer,
    TemplateList,
    TemplateItem,
    TemplateButton,
} from "./TemplatesPage.styles";
import Sidebar from "../../components/Sidebar";
import { fetchDocumentTemplates, generateDocument } from "../../services/documentService/documentService";
import { DocumentTemplate, TemplateSchema } from "../../services/documentService/documentService.types";
import DocumentModal from "../../components/Modals/DocumentModal/DocumentModal";
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import { Toast } from "primereact/toast";

const TemplatesPage: React.FC = () => {
    const [templates, setTemplates] = useState<DocumentTemplate[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentGroupIndex, setCurrentGroupIndex] = useState<number>(0);
    const [currentTemplate, setCurrentTemplate] = useState<DocumentTemplate | null>(null);
    const [collectedData, setCollectedData] = useState<Record<string, string>>({});
    const [generating, setGenerating] = useState<boolean>(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const toast = useRef<Toast>(null);

    useEffect(() => {
        const loadTemplates = async () => {
            try {
                setLoading(true);
                const fetchedTemplates = await fetchDocumentTemplates();
                setTemplates(fetchedTemplates);
            } catch (err) {
                setError("Ошибка при загрузке шаблонов");
            } finally {
                setLoading(false);
            }
        };

        loadTemplates();
    }, []);

    const openModal = (template: DocumentTemplate) => {
        setCurrentTemplate(template);
        setCurrentGroupIndex(0);
        setIsModalOpen(true);
    };

    const handleModalSubmit = async (formData: Record<string, string>) => {
        setCollectedData((prevData) => ({
            ...prevData,
            ...formData,
        }));

        if (currentTemplate && currentGroupIndex < currentTemplate.question_groups.length - 1) {
            setCurrentGroupIndex(currentGroupIndex + 1);
        } else {
            try {
                setGenerating(true);
                const blob = await generateDocument(
                    {
                        template_id: currentTemplate?.id || 0,
                        replacements: { ...collectedData, ...formData },
                    }
                );

                if (!(blob instanceof Blob)) {
                    console.error("Некорректный формат ответа: ожидается Blob");
                    toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Некорректный формат ответа: ожидается Blob", life: 3000 });
                    return;
                }
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'download.docx';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);

                setIsSuccessModalOpen(true);
                setTimeout(() => setIsSuccessModalOpen(false), 2000);
            } catch (error) {
                console.error("Ошибка при генерации документа:", error);
                toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка при генерации документа", life: 3000 });
            } finally {
                setGenerating(false);
                setIsModalOpen(false);
            }
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCollectedData({});
    };

    return (
        <ProfilePageContainer>
            <Toast ref={toast} />
            <Sidebar />
            <ContentWrapper>
                <HeaderContainer>
                    <Title>Шаблоны документов</Title>
                </HeaderContainer>
                {loading && <p>Загрузка...</p>}
                {error && <p>{error}</p>}
                {!loading && !error && (
                    <TemplateList>
                        {templates.map((template) => (
                            <TemplateItem key={template.id}>
                                <TemplateButton onClick={() => openModal(template)}>
                                    {template.title}
                                </TemplateButton>
                            </TemplateItem>
                        ))}
                    </TemplateList>
                )}
                {isModalOpen && currentTemplate && (
                    <DocumentModal
                        title={currentTemplate.title}
                        templateId={currentTemplate.id}
                        fields={currentTemplate.question_groups[currentGroupIndex].schemas.map(
                            (schema: TemplateSchema) => ({
                                id: schema.id,
                                name: schema.key,
                                type: schema.answer_options.length > 0 ? "select" : "text",
                                placeholder: schema.question || "",
                                required: schema.required,
                                options: schema.answer_options.map((option) => option.value),
                            })
                        )}
                        onSubmit={handleModalSubmit}
                        onCancel={closeModal}
                        submitButtonText={
                            generating
                                ? "Генерация..."
                                : currentGroupIndex === currentTemplate.question_groups.length - 1
                                    ? "Сгенерировать"
                                    : "Подтвердить"
                        }
                    />
                )}
                {isSuccessModalOpen && (
                    <SuccessModal
                        message="Документ успешно сгенерирован"
                        onClose={() => setIsSuccessModalOpen(false)}
                    />
                )}
            </ContentWrapper>
        </ProfilePageContainer>
    );
};

export default TemplatesPage;
