import styled from "styled-components";
import dropDownArrowIcon from "../../assets/icons/dropDownArrowIcon.svg";

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 8px;
  background: rgb(250, 250, 250);
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;

  &::after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(${dropDownArrowIcon}) no-repeat center center;
    background-size: contain;
    transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : "0deg")});
    transition: transform 0.2s ease-in-out;
  }
`;

export const DropdownList = styled.ul<{ openUpward: boolean }>`
  position: absolute;
  ${({ openUpward }) => (openUpward ? "bottom: 100%;" : "top: 100%;")}
  left: 0;
  right: 0;
  background: white;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
`;

export const DropdownListItem = styled.li`
  padding: 10px 16px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;

  &:hover {
    background: rgba(229, 229, 229, 0.5);
  }
`;
