import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const ButtonContainer = styled.div<{ noLessons?: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 8px;
  background: rgb(250, 250, 250);
  gap: 6px;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: rgb(243, 243, 243);
  }

  ${({ noLessons }) =>
    noLessons &&
    css`
      // cursor: default;
      // border: none;
      
      // &:hover {
      //   background-color: rgb(250, 250, 250); /* Отключаем hover */
      // }
    `}
`;

export const ButtonIcon = styled.img`
  width: 22.5px;
  height: 15px;
  color: rgb(122, 92, 224);
`;

export const ButtonText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: rgb(31, 10, 53);
  text-decoration: none;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;