import React, { useState, useEffect, useRef } from "react";
import { fetchAssignmentResponses } from "../../services/assignmentResponseService/assignmentResponseService";
import {
  AssignmentResponsesPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  Counter,
  StatsWrapper,
  StatBlock,
} from "./AssignmentResponsesPage.styles";
import Sidebar from "../../components/Sidebar";
import { AssignmentResponseRow } from "./AssignmentResponsesPage.types";
import Table from "../../components/Table";
import StatusBadge from "../../components/StatusBadge";
import LessonsButton from "../../components/Buttons/LessonsButton";
import { Toast } from "primereact/toast";

const AssignmentResponsesPage: React.FC = () => {
  const [assignmentResponses, setAssignmentResponses] = useState<AssignmentResponseRow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");
  const [unreviewedCount, setUnreviewedCount] = useState<number>(0);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    loadAssignmentResponses();
  }, [currentPage, pageSize, orderBy]);


  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const loadAssignmentResponses = async () => {
    setLoading(true);
    try {
      const response = await fetchAssignmentResponses(currentPage, pageSize, orderBy);
      setAssignmentResponses(
        response.results.assignment_responses.map((response) => ({
          id: response.id,
          studentName: response.student.full_name,
          status: (
            <StatusBadge
              text={response.is_reviewed ? "ПРОВЕРЕНО" : "НЕ ПРОВЕРЕНО"}
              variant={response.is_reviewed ? "green" : "red"}
            />
          ),
          assignmentTitle: (
            <LessonsButton
              href={`/assignment-responses/${response.id}`} // Изменён маршрут
              text="Смотреть"
            />
          ),
          telegram: (
            <a
              href={`https://t.me/${response.student.user_name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              @{response.student.user_name}
            </a>
          ),
          phoneNumber: response.student.phone_number,
        }))
      );
      
      setUnreviewedCount(response.results.unreviewed_count);
      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (error) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки ответов на задания", life: 3000 });
      console.error("Ошибка при загрузке ответов на задания:", error);
    } finally {
      setLoading(false);
    }
  };

  const headers = [
    { label: "ФИО", key: "studentName", fieldName: "student__full_name" },
    { label: "Статус", key: "status", fieldName: "is_reviewed" },
    { label: "Задания", key: "assignmentTitle"},
    { label: "Telegram", key: "telegram", fieldName: "student__user_name" },
    { label: "Номер телефона", key: "phoneNumber", fieldName: "student__phone_number" },
  ];

  const rowAmountOptions = [
    { label: "7 записей", value: 7 },
    { label: "15 записей", value: 15 },
    { label: "20 записей", value: 20 },
  ];

  return (
    <AssignmentResponsesPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Домашние задания</Title>
          <StatsWrapper>
            <StatBlock>Непроверенных заданий:</StatBlock>
            <Counter>{unreviewedCount}</Counter>
          </StatsWrapper>
        </HeaderContainer>
        <Table
          headers={headers}
          rows={assignmentResponses}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          onSort={handleSort}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowAmountOptions={rowAmountOptions}
          loading={loading}
          minHeight="200px"
        />
      </ContentWrapper>
    </AssignmentResponsesPageContainer>
  );
};

export default AssignmentResponsesPage;
