import axiosInstance from "../axiosConfig";

// Получение данных пользователя
export const getUserData = async (
  userId: number
): Promise<{
  organization_name: string;
  first_name: string;
  last_name: string;
  email: string;
  referral_token: string;
  verification_code: string;
}> => {
  const url = `/users/${userId}/`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе данных пользователя:", error);
    throw error;
  }
};

// Обновление данных пользователя
export const updateUserData = async (
  userId: number,
  data: {
    organization_name?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    verification_code?: string;
  }
): Promise<void> => {
  const url = `/users/${userId}/`;
  try {
    await axiosInstance.patch(url, data);
  } catch (error) {
    console.error("Ошибка при обновлении данных пользователя:", error);
    throw error;
  }
};

// Получение настроек бота
export const getBotConfig = async (
  userId: number
): Promise<
  {
    id: number;
    user: number;
    tg_id: number;
    bot_token: string;
    ukassa_token: string;
    tg_name: string;
  }[]
> => {
  const url = `/bot_config/?user_id=${userId}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе настроек бота:", error);
    throw error;
  }
};

// Создание настроек бота
export const createBotConfig = async (
  data: {
    user: number;
    tg_id: number;
    bot_token: string;
    ukassa_token: string;
    tg_name: string;
  }
): Promise<void> => {
  const url = `/bot_config/`;
  try {
    await axiosInstance.post(url, data);
  } catch (error) {
    console.error("Ошибка при создании настроек бота:", error);
    throw error;
  }
};

// Обновление настроек бота
export const updateBotConfig = async (
  botConfigId: number,
  userId: number,
  data: {
    tg_id?: number;
    bot_token?: string;
    ukassa_token?: string;
    tg_name?: string;
  }
): Promise<void> => {
  const url = `/bot_config/${botConfigId}/?user_id=${userId}`;
  try {
    await axiosInstance.patch(url, data);
  } catch (error) {
    console.error("Ошибка при обновлении настроек бота:", error);
    throw error;
  }
};
