import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  SupportResponsePageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  ResponseContent,
  TitleWrapper,
  ResponseTitle,
  Answer,
} from "./SupportResponsePage.styles";
import Sidebar from "../../components/Sidebar";
import { fetchSupportRequestById } from "../../services/supportRequestService/supportRequestService";
import { Toast } from "primereact/toast";

const SupportResponsePage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Получаем id из URL
  const navigate = useNavigate(); // Для возврата назад
  const [response, setResponse] = useState<any>(null); // Состояние для данных
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        const data = await fetchSupportRequestById(Number(id));
        setResponse(data);
      } catch (error) {
        setError("Не удалось загрузить данные ответа");
        console.error(error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Не удалось загрузить данные ответа", life: 3000 });
      } finally {
        setLoading(false);
      }
    };

    fetchResponse();
  }, [id]);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <SupportResponsePageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Поддержка</Title>
          <TitleWrapper>
            <h5>Тема</h5>
            <ResponseTitle>Ответ на запрос</ResponseTitle>
          </TitleWrapper>
        </HeaderContainer>
        <ResponseContent>
          <h5>Ответ</h5>
          <Answer>
            {response.response_content || "Ответ пока не получен."}
          </Answer>
        </ResponseContent>
      </ContentWrapper>
    </SupportResponsePageContainer>
  );
};

export default SupportResponsePage;
