import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: radial-gradient(
      51% 51% at 50% 121%,
      rgba(131, 102, 228, 0.6),
      rgba(131, 102, 228, 0)
    ),
    rgb(247, 248, 248);
`;

export const Logo = styled.h1`
  color: rgb(114, 82, 220);
  font-size: 1.81rem;
  font-weight: 700;
  line-height: 2.19rem;
  margin-bottom: auto;
  margin-top: 30px;
`;

 