import React from "react";
import { StatWindowContainer, StatNumber, StatTitle, ButtonWrapper, StatWrapper, StatButton } from "./StatWindow.styles";
import { StatWindowProps } from "./StatWindow.types";
import Button from "../Buttons/Button";

const StatWindow: React.FC<StatWindowProps> = ({ title, value, onButtonClick }) => {
  return (
    <StatWindowContainer>
      <StatWrapper>
      <StatTitle>{title}</StatTitle>
      <StatNumber>{value.toLocaleString("ru-RU")}</StatNumber>
      </StatWrapper>
      <ButtonWrapper>
        <StatButton onClick={onButtonClick}>
          Смотреть статистику
        </StatButton>
      </ButtonWrapper>
    </StatWindowContainer>
  );
};

export default StatWindow;
