import axios from "axios";
import axiosInstance from "../axiosConfig";
import { ReferralResponse } from "./referralService.types";

// Получение данных по рефералам
export const fetchReferralData = async (): Promise<ReferralResponse> => {
  const url = `/courses/user-stats/`;
  try {
    const response = await axiosInstance.get<ReferralResponse>(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении данных по рефералам:", error);
    throw error;
  }
};

// Получение реферальной ссылки для пользователя
export const fetchReferralLink = async (userId: number): Promise<string> => {
  const url = `/users/${userId}/`;

  try {
    const response = await axiosInstance.get<{ referral_token: string }>(url);
    const referralToken = response.data.referral_token;

    // Автоматически получаем текущий хост
    const frontendUrl = window.location.origin; 

    const referralLink = `${frontendUrl}/?referral_token=${referralToken}`;

    return referralLink;
  } catch (error) {
    console.error("Ошибка при получении реферальной ссылки:", error);
    throw error;
  }
};

// Создание заявки на вывод средств
export const createBalanceOrder = async (data: {
  requisites: string;
  balance_sum: number;
}): Promise<void> => {
  const url = `/courses/user/balance-order/create/`;
  try {
    const response = await axiosInstance.post(url, data);
    console.log("Заявка успешно создана:", response.data);
  } catch (error) {
    console.error("Ошибка при создании заявки на вывод средств:", error);
    throw error;
  }
};


export const referralService = {
  trackClick: async (referralToken: string) => {
    try {
      const response = await axiosInstance.post('/auth/ref-track-click/', {
        referral_token: referralToken,
      });
      return response.data;
    } catch (error) {
      console.error('Ошибка при отслеживании клика по реферальной ссылке:', error);
      throw error;
    }
  },
};

