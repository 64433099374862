import styled, { css } from "styled-components";
import { ButtonProps } from "./Button.types";

export const StyledButton = styled.button<ButtonProps>`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "50px"};
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease;
  border: none;
  margin: 8px;

  ${({ variant, disabled, isStaticOnHover }) => {
    if (disabled && variant !== "flatNotFilled") {
      return css`
        box-shadow: inset 0px -2px 0px 0px rgb(93, 68, 176);
        background: rgb(114, 82, 220);
        opacity: 0.3;
      `;
    }

    const hoverEffect = isStaticOnHover
      ? ""
      : css`
          &:hover {
            transform: translateY(-3px);
          }
        `;

    switch (variant) {
      case "active":
        return css`
          box-shadow: inset 0px -2px 0px 0px rgb(93, 68, 176),
            0px 7px 11px 0px rgb(121, 90, 223);
          background: linear-gradient(
              180deg,
              rgba(189, 169, 255, 0.7),
              rgba(114, 82, 220, 0.5) 100%
            ),
            rgb(114, 82, 220);

          ${hoverEffect}
        `;
      case "flatNotFilled":
        return css`
          ${disabled
            ? css`
                border: 1px solid rgba(114, 82, 220, 0.3);
                color: rgba(114, 82, 220, 0.3);
                background: rgba(255, 255, 255, 0.5);
                cursor: not-allowed;
              `
            : css`
                border: 1px solid rgb(114, 82, 220);
                border-radius: 12px;
                color: rgb(114, 82, 220);
                background: rgb(255, 255, 255);

                ${hoverEffect}
              `}
        `;
      case "flatFilled":
        return css`
          border-radius: 12px;
          background: rgb(144, 112, 245);

          ${hoverEffect}
        `;
      case "backgroungNone":
        return css`
          border-radius: 12px;
          background: none;
          border: 1px solid rgb(114, 82, 220);
          color: rgb(114, 82, 220);

          ${hoverEffect}
        `;

      case "red":
        return css`
          border-radius: 12px;
          box-shadow: inset 0px -2px 0px 0px rgb(234, 0, 4),
            0px 7px 11px 0px rgb(223, 90, 92);
          background: linear-gradient(
              180deg,
              rgba(255, 169, 170, 0.44),
              rgba(221, 0, 4, 0.5) 100%
            ),
            rgb(237, 0, 4);
          color: rgb(255, 255, 255);

          ${hoverEffect}
        `;
        
      case "pink":
        return css`
          border-radius: 12px;
          box-shadow: inset 0px -2px 0px 0px rgb(167, 1, 107),
            0px 7px 11px 0px rgb(185, 0, 118);
          background: linear-gradient(
              180deg,
              rgba(235, 0, 98, 0.44),
              rgba(211, 4, 90, 0.5) 100%
            ),
            rgb(218, 0, 185);
          color: rgb(255, 255, 255);

          ${hoverEffect}
        `;

      case "default":
        return css`
          box-shadow: inset 0px -2px 0px 0px rgb(93, 68, 176),
            0px 7px 11px 0px rgb(121, 90, 223);
          background: linear-gradient(
              180deg,
              rgb(189, 169, 255),
              rgb(114, 82, 220) 100%
            ),
            rgb(114, 82, 220);

          ${hoverEffect}
        `;
      default:
        return "";
    }
  }};
`;
