import React from "react";
import ReactDOM from "react-dom";
import { ConfirmModalProps } from "./ConfirmModal.types";
import { CloseButton, Divider, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "./ConfirmModal.styles";
import Button from "../../Buttons/Button";
import closeIcon from "../../../assets/icons/closeIcon.svg";

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>{title}</h2>
          <CloseButton onClick={onCancel}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </ModalHeader>
        <Divider></Divider>
        <ModalBody>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button variant="flatNotFilled" onClick={onCancel}>
            Отмена
          </Button>
          <Button variant="flatFilled" onClick={onConfirm} width="175px">
            Удалить
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>,
    document.body
  );
};

export default ConfirmModal;
