import styled from "styled-components";

import dropDownArrowIcon from "../../assets/icons/dropDownArrowIcon.svg";

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    font-size: 16px;
    // font-weight: 700;
  }
`;

export const DropdownInput = styled.input`
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 10px;
  background: rgb(247, 248, 248);
  cursor: pointer;
  width: 100%;

  &:focus {
    border: 1px solid rgb(114, 82, 220);
    outline: none;
    background: rgba(114, 82, 220, 0.15);
  }
`;

export const DropdownIcon = styled.span<{ isOpen: boolean }>`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%)
    rotate(${({ isOpen }) => (isOpen ? "0deg" : "180deg")});
  transition: transform 0.2s ease-in-out;
  width: 24px;
  height: 24px;
  background: url(${dropDownArrowIcon}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 10px;
  background: rgb(249, 249, 249);

  padding: 13px 16px 13px 16px;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const DropdownItem = styled.li<{ isSelected?: boolean }>`
  padding: 13px 0px 13px 0px;
  cursor: pointer;
  list-style: none;
  color: rgb(39, 39, 39);
  font-size: 16px;
  // font-weight: 700;

  color: ${({ isSelected }) =>
    isSelected ? "rgb(39, 39, 39);" : "rgba(31, 10, 53, 0.8)"};

  &:hover {
    color: rgb(114, 82, 220);
  }
`;
