import styled from "styled-components";

export const TooltipContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid rgba(114, 82, 220, 0.4);
  border-radius: 6px;
  background: rgba(114, 82, 220, 0.05);
  padding: 6px 12px 6px 12px;
`;

export const TooltipDate = styled.p`
  color: rgb(114, 82, 220);
  font-size: 8px;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

export const TooltipRow = styled.p`
  color: rgb(114, 82, 220);
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  margin: 0;
`;
