import axiosInstance from "../axiosConfig";
import { APIResponse, Payment, PaymentData } from "./paymentsService.types";

export const fetchPayments = async (
    page: number = 1,
    pageSize: number = 7,
    ordering: string = ""
  ): Promise<APIResponse<PaymentData>> => {
    const url = `/courses/payments-list/`;
    try {
      const response = await axiosInstance.get(url, {
        params: {
          page,
          page_size: pageSize,
          ordering,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Ошибка при получении списка платежей:", error);
      throw error;
    }
  };
