import React, { useState, useEffect, useRef } from "react";
import {
  StudentsPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  TableWrapper,
  StatsWrapper,
  StatBlock,
} from "./StudentsPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import { fetchStudents } from "../../services/studentService/studentService";
import { StudentPurchase } from "../../services/studentService/studentService.types";
import { Toast } from "primereact/toast";

const StudentsPage: React.FC = () => {
  const [students, setStudents] = useState<StudentPurchase[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");
  const [studentCount, setStudentCount] = useState<number>(0);
  const [courseCount, setCourseCount] = useState<number>(0);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    loadStudents();
  }, [currentPage, pageSize, orderBy]);

  const loadStudents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchStudents(
        currentPage,
        pageSize,
        orderBy
      );
  
      console.log(response)
      setStudents(response.results.results);
      setTotalPages(Math.ceil(response.count / pageSize));
      setStudentCount(response.results.aggregated_data.student_count);
      setCourseCount(response.results.aggregated_data.course_count);
    } catch (err) {
      console.error("Ошибка при загрузке учеников:", err);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки учеников", life: 3000 });
      setError("Не удалось загрузить список учеников.");
    } finally {
      setLoading(false);
    }
  };
  

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const headers = [
    { label: "ФИО", key: "fullName", fieldName: "student__user_name" },
    { label: "Телеграм", key: "telegram", fieldName: "student__user_name" },
    { label: "Номер телефона", key: "phone", fieldName: "student__phone_number" },
    { label: "Почта", key: "email", fieldName: "student__email" },
    { label: "Название курса", key: "courseName", fieldName: "course__title" },
  ];

  const rows = students.map((student) => ({
    fullName: student.student.full_name,
    telegram: (
      <a href={`https://t.me/${student.student.user_name}`} target="_blank" rel="noopener noreferrer">
        @{student.student.user_name}
      </a>
    ),
    phone: student.student.phone_number,
    email: student.student.email,
    courseName: student.course.title,
  }));

  const rowAmountOptions = [
    { label: "7 учеников", value: 7 },
    { label: "15 учеников", value: 15 },
    { label: "20 учеников", value: 20 },
  ];

  return (
    <StudentsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Список учеников</Title>
          {/* Блок с общей информацией */}
          <StatsWrapper>
            <StatBlock>
              Всего учеников: <span>{studentCount}</span>
            </StatBlock>
            <StatBlock>
              Купили курс: <span>{courseCount}</span>
            </StatBlock>
          </StatsWrapper>
        </HeaderContainer>
            <Table
              headers={headers}
              rows={rows}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              onSort={handleSort}
              rowAmountOptions={rowAmountOptions}
              minHeight="230px"
              loading={loading}
            />
      </ContentWrapper>
    </StudentsPageContainer>
  );
};

export default StudentsPage;
