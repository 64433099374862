import styled from "styled-components";

export const StatWindowContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
align-items: start;
box-sizing: border-box;
border: 1px solid rgba(31, 10, 53, 0.1);
border-radius: 16px;

box-shadow: 0px 0px 0px 6px rgba(31, 10, 53, 0.05);
background: rgb(255, 255, 255);
`;


export const StatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 16px 16px 16px 16px;
`;

export const StatTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #1f0a35;
  text-align: center;
`;

export const StatNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #1f0a35;
`;


export const StatButton = styled.button`
padding: 14px 28px 14px 28px;
border-radius: 10px;
background: rgba(114, 82, 220, 0.1);
color: rgb(114, 82, 220);
font-size: 16px;
font-weight: 600;
border: none;
cursor: pointer;
width: 100%;

`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 8px 8px 8px;
`;
