import styled from "styled-components";

export const AssignmentResponsesPageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const Counter = styled.div`
color: rgb(31, 10, 53);
font-size: 28px;
font-weight: 600;
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 16px 16px 16px 38px;
  gap: 10;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06),
    0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);
`;

export const StatBlock = styled.div`
color: rgba(31, 10, 53, 0.5);
font-size: 16px;
font-weight: 400;
`;
